import react from 'react';
import getImage from '../lib/getImage';
import style from './style/meetingStep.module.css';
import { Image, Divider } from 'antd-mobile'



const MeetingStep = (props) =>
{
    return (
        <div className={style.main}>
            <div className={style.mainBox}>
                {StepItem(props, 'meetinginfo', '会议信息', 1)}
                <div style={{ width: '3rem', display: 'inline-block', verticalAlign: 'middle' }}>
                    <Divider
                        style={{
                            borderColor: '#9B999E',
                            borderStyle: 'dashed',
                        }}
                    >
                    </Divider>
                </div>
                {StepItem(props, 'posticon', '参会信息', 2)}
            </div>
        </div>
    );
}

const StepItem = (props, icon, description, actIndex) =>
{
    return (<div className={style.stepBase} >
        <Image src={getImage(`${icon}${props.act === actIndex ? '_press' : ''}.svg`)} width='100%' />
        <div className={[style.description, (props.act === actIndex ? style.descriptionAct : '')].join(' ')}>
            {description}
        </div>
    </div>)
}

export default MeetingStep;