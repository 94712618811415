import React from "react"
import style from './style/meetingItem.module.css'
import getImage from '../lib/getImage';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';



const MeetingItem = (props) =>
{
    const meetingDetail = props.meetingDetail;
    const navigate = useNavigate();
    return (
        <div className={style.main} onClick={() =>
        {
            if (!props.itemClick)
                return;
            if (props.isNav && props.isNav(meetingDetail))
            {
                const toUrl = props.itemClick(meetingDetail);
                navigate(toUrl);
            }
            else
            {
                props.itemClick(meetingDetail)
            }

        }} >
            <div className={`${style.imgBox} ${style.baseBox}`}>
                <img src={getImage(meetingDetail.cover)}></img>
            </div>
            <div className={`${style.detailBox} ${style.baseBox}`}>
                <div className={style.status}><a>{meetingDetail.state === 1 ? "未开始" : meetingDetail.state === 2 ? "进行中" : "已结束"}</a></div>
                <div className={style.title}>{meetingDetail.subject}</div>
                <div className={style.datetime}>{moment(meetingDetail.startTime).format('YYYY-MM-DD HH:mm')}</div>
            </div>
        </div>
    )
}


export default MeetingItem;