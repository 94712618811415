import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Toast } from 'antd-mobile'
import style from './style/insideMeetingCreate.module.css';

//component
import { FormGroups } from '../component/formgroups';
import FormButtonBox from '../component/formbuttonbox';

//lib
import { ApiPost, ApiGet } from '../lib/apihelper';
import getImage from '../lib/getImage';
import moment from "moment";

const defaultFromLayOut = {
    main: [
        {
            layOut: [
                {
                    type: "FormInput",
                    label: "会议名称",
                    keyWord: "subject",
                    // placeholder:""
                    // readOnly:false
                }
            ]
        },
        {
            layOut: [
                {
                    type: 'DateInput',
                    label: "会议开始时间",
                    keyWord: "startTime",
                    // placeholder:""
                    // readOnly:false
                },
                {
                    type: 'DateInput',
                    label: "会议结束时间",
                    keyWord: "endTime",
                    // placeholder:""
                    // readOnly:false
                },
                {
                    type: "SelectInput",
                    label: "预计线上人数",
                    keyWord: "meetingNumber",
                    // placeholder:"",
                    valueFormat: "{value}人",
                    options: [
                        [
                            { label: "50", value: 50 },
                            { label: "300", value: 300 },
                            { label: "500", value: 500 },
                        ]
                    ],
                    // readOnly:false
                },
                {
                    type: "CheckInput",
                    label: "是否开启会议密码",
                    keyWord: "isOpenPwd",
                    // readOnly:false
                },
                // {
                //     type: "FormInput",
                //     label: "会议密码",
                //     keyWord: "pwd",
                //     hide: (values) =>
                //     {
                //         return values['isOpenPwd'] === true;
                //     },
                //     // placeholder:""
                //     // readOnly:false
                // }
            ]
        },
        // {
        //     layOut: [
        //         {
        //             type: "CheckInput",
        //             label: "是否开启会议报名",
        //             keyWord: "isOpenSignUp",
        //             readOnly:false
        //         },
        //         {
        //             type: "CheckInput",
        //             label: "是否开启等候室",
        //             keyWord: "isOpenWaitRoom",
        //             // readOnly:false
        //         },
        //         {
        //             type: "CheckInput",
        //             label: "是否允许成员在主持人入会前加入会议",
        //             keyWord: "allowInBeforeHost",
        //             // readOnly:false
        //         },
        //         {
        //             type: "CheckInput",
        //             label: "是否开启会议水印",
        //             keyWord: "isOpenWatermark",
        //             // readOnly:false
        //         },
        //     ]
        // },
        {
            title: "成员入会时静音",
            layOut: [
                {
                    type: 'RadioInput',
                    keyWord: "isOpenMute",
                    options: [
                        { label: "开启", value: 1 },
                        { label: "关闭", value: 2 },
                        { label: "入会6人以上自动静音", value: 3 },
                    ],
                    // readOnly:true
                }
            ]
        },
    ]
}

const defaultValue = () =>
{
    return {
        meetingNo: "",
        meetingType: 1,
        subject: "",
        startTime: "",
        endTime: "",
        meetingNumber: "",
        isOpenPwd: false,
        pwd: "",
        isOpenSignUp: false,
        isOpenWaitRoom: false,
        allowInBeforeHost: false,
        isOpenWatermark: false,
        isOpenMute: null,
        isOpenMeeting: true,
        cover: "",
        extensionData: "",
        isActive: true,
        id: 0
    }
}

const convantPostdata = (obj) =>
{
    return {
        meeting: obj,
        meetingPlaybills: [],
        meetingSchedules: []
    }
}

const creatOrEdit = (meetingDetail, navigate) =>
{
    const meeting = { ...meetingDetail };
    if (!meeting.subject)
    {
        Toast.show({ content: '请输入会议名称' });
        return;
    }
    if (!meeting.startTime || !meeting.endTime)
    {
        Toast.show({ content: '请选择会议时间' });
        return;
    }
    else
    {
        const start = moment(meeting.startTime);
        const end = moment(meeting.endTime);
        if (end.diff(start) < 0)
        {
            Toast.show({ content: '开始时间大于结束时间' });
            return;
        }
    }
    if (!meeting.meetingNumber || meeting.meetingNumber === 0)
    {
        Toast.show({ content: '请选择预计上线人数' });
        return;
    }
    // if (meeting.isOpenPwd && !meeting.pwd)
    // {
    //     Toast.show({ content: '请输入会议密码' });
    //     return;
    // }
    // else
    // {
    //     meeting.pwd = "";
    // }
    if (!meeting.isOpenMute)
    {
        Toast.show({ content: '成员入会时是否静音' });
        return;
    }

    const postData = convantPostdata(meeting);
    return ApiPost('createOrEditMeeting', postData).then(x => navigate('/MeetingListInside'));
}

const getMeeting = (id) =>
{
    return ApiPost('getMeetingById', { id });
}



const InsideMeetingCreate = (props) =>
{
    const { id } = useParams();
    const [formVariable, setFormVariable] = useState(defaultValue());
    const [fromLayOut, setFormLayOut] = useState(defaultFromLayOut);
    useEffect(() =>
    {
        if (id)
        {
            getMeeting(id).then(result => setFormVariable(result.data.result.meeting))
        }
    }, []);

    const navigate = useNavigate();
    const buttons = [
        // <Button style={{ width: '100%' }} fill='outline' shape='rounded' color='primary' onClick={navigate.bind(this, -1)} >上一步</Button>,
        <Button onClick={creatOrEdit.bind(this, formVariable, navigate)} style={{ width: '100%', fontWeight: 'bold' }} shape='rounded' color='primary'>保存</Button>
    ];

    return (
        <div className="mainBody">
            <FormGroups fromLayOut={fromLayOut} setFormLayOut={setFormLayOut} formVariable={formVariable} setFormVariable={setFormVariable} ></FormGroups>
            <FormButtonBox buttons={buttons} style={{ marginTop: '3rem' }} ></FormButtonBox>
        </div>
    )
}

const getLayOutGroup = (layOut, groupIndex) =>
{
    return layOut.main[groupIndex];
}

const getLayOutItem = (layOut, groupIndex, itemIndex) =>
{
    return getLayOutGroup(layOut, groupIndex).layOut[itemIndex];
}

export default InsideMeetingCreate;