import React, { useEffect, useState } from "react";
import getImage from '../lib/getImage';
import { useNavigate, useParams } from 'react-router-dom';
import { Input, Toast, Button, Dialog, Modal } from 'antd-mobile';
import { CheckCircleFill } from 'antd-mobile-icons';
import { ApiPostWithOutAuth, ApiGetWithOutAuth } from '../lib/apihelper';
import { FormGroups } from '../component/formgroups'
import FormButtonBox from '../component/formbuttonbox'
import GetUserInfo from '../lib/userinfoHelper';
import moment from "moment";
import confrimData from '../lib/confrimData'


const getDefaultFromLayOut = (userinfo, setUserinfo) =>
{
    return {
        main: [
            {
                layOut: [
                    {
                        type: "FormInput",
                        label: "手机号码",
                        keyWord: "phone",
                        placeholder: "请填写手机号码",
                        // userDefinedStype: { '--text-align': 'left', }
                    },
                    {
                        type: "UserDefinedInput",
                        label: "验证码",
                        myInput: (
                            <div style={{ width: '100%' }}>
                                <Input
                                    value={userinfo.verifyCode}
                                    style={{
                                        '--text-align': 'right',
                                        '--placeholder-color': '#8B8C8E',
                                        '--font-size': '1rem'
                                    }}
                                    onChange={(verifyCode) =>
                                    {
                                        const data = { ...userinfo };
                                        data.verifyCode = verifyCode
                                        setUserinfo(data);
                                    }}
                                    placeholder="请输入验证码" />
                                <VerifyPhoneCodeDom phone={userinfo.phone} style={{ marginTop: '.5rem' }}></VerifyPhoneCodeDom>
                            </div>
                        )
                    }
                ]
            },
        ]
    }
}

const getUserinfoFromLayOut = (checked, setChecked) =>
{
    return {
        main: [
            {
                layOut: [
                    {
                        type: "FormInput",
                        label: "姓名",
                        keyWord: "name",
                    },
                    {
                        type: "FormInput",
                        label: "医院",
                        keyWord: "hospital",
                    },
                    {
                        type: "FormInput",
                        label: "科室",
                        keyWord: "department",
                    },
                    {
                        type: "FormInput",
                        label: "职务",
                        keyWord: "administrativePosition",
                    },
                    <div style={{ padding: '1rem 1.2rem' }}>
                        <div className={{
                            paddingLeft: '.3rem',
                            color: '#8B8C8E'
                        }}
                            onClick={() =>
                                Modal.confirm({
                                    title: '',
                                    content: <div dangerouslySetInnerHTML={{ __html: confrimData }}></div>,
                                    confirmText: '同意',
                                    cancelText: '拒绝',
                                    onConfirm: () =>
                                    {
                                        setChecked(true);
                                    },
                                    onCancel: () =>
                                    {
                                        setChecked(false);
                                    }
                                })
                            }
                        >
                            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                                <CheckCircleFill fontSize='1.4rem' color={checked ? 'var(--adm-color-primary)' : '#DEE0E4'} />
                            </div>
                            <div style={{ display: 'inline-block', verticalAlign: 'middle', paddingLeft: '.3rem' }}>
                                <span style={{ fontSize: '1rem', fontWeight: '700', color: '#8B8C8E' }}>确认并同意《用户隐私条款》</span>
                            </div>
                        </div>
                        {/* <Checkbox disabled checked={true} ></Checkbox> */}
                    </div>
                ]
            },
        ]
    }
}

const phoneCodeVerifyHelper = {
    phoneCodeInfo: null,
    verify: function (phone, code)
    {
        return this.phoneCodeInfo && this.phoneCodeInfo.phone && this.phoneCodeInfo.code && phone && code && this.phoneCodeInfo.phone === phone && this.phoneCodeInfo.code === code;
    },
    saveTempInfo: function (phone, code)
    {
        this.phoneCodeInfo = { phone, code };
    }
}


const EnterMeeting = (props) =>
{
    const { unionId, meetingId, jumpType } = useParams();
    const [phoneVerifyLayout, setPhoneVerifyLayout] = useState({ main: [] });
    const [userInfoLayout, setUserInfoLayout] = useState({ main: [] });
    const [userinfo, setUserinfo] = useState({ name: "", unionId });
    const [phoneValidate, setPhoneValidate] = useState(true);
    const [showPage, setShowPage] = useState(false);
    const [checked, setChecked] = useState(false);

    // 页面首次加载
    useEffect(() =>
    {
        CheckIsUserRegister(unionId, setUserinfo).then(userinfoResult =>
        {
            console.log("userinfoResult",userinfoResult);
            
            if (userinfoResult && userinfoResult.isRegister !== '0' && userinfoResult.status !== 'unverified')
            {
                TryEnterMeeting(userinfoResult, jumpType, meetingId);
            }
            /*
            else if (userinfoResult && userinfoResult.isRegister === '1')
            {
                Dialog.show({
                    content: `是否需要修改绑定的手机号？（当期那手机号为：${userinfoResult.phone}）`,
                    closeOnAction: true,
                    actions: [
                        [
                            {
                                key: 'cancel',
                                text: '跳过',
                                onClick: () =>
                                {
                                    setShowPage(true);
                                    setPhoneValidate(true);
                                }
                            },
                            {
                                key: 'ok',
                                text: '修改',
                                onClick: () =>
                                {
                                    setShowPage(true);
                                }
                            },
                        ],
                    ],
                })
            }
            */
            else
            {
                setShowPage(true);
            }
        });
    }, [])

    // 内容变化时更新UI
    useEffect(() =>
    {
        setPhoneVerifyLayout(getDefaultFromLayOut(userinfo, setUserinfo));
        setUserInfoLayout(getUserinfoFromLayOut(checked, setChecked));
    }, [userinfo, checked])
    return (
        <div className="mainBody">
            {showPage ? [phoneValidate ?
                <FormGroups fromLayOut={userInfoLayout} setFormLayOut={setUserInfoLayout} formVariable={userinfo} setFormVariable={setUserinfo} ></FormGroups> :
                <FormGroups fromLayOut={phoneVerifyLayout} setFormLayOut={setPhoneVerifyLayout} formVariable={userinfo} setFormVariable={setUserinfo} ></FormGroups>
                , <FormButtonBox buttons={[<Button style={{ width: '50%', fontWeight: 'bold' }} shape='rounded' color='primary' onClick={() =>
                {
                    // 已验证手机
                    if (phoneValidate)
                    {
                        if (checked)
                        {
                            // 注册用户
                            TryRegisterMeetingUser(userinfo).then(() =>
                            {
                                TryEnterMeeting(userinfo, jumpType, meetingId);
                            });
                        }
                        else
                        {
                            Toast.show({
                                icon: 'fail',
                                content: "请阅读并同意《用户隐私条款》",
                            });
                        }


                    }
                    // 进行手机验证
                    else if (phoneCodeVerifyHelper.verify(userinfo.phone, userinfo.verifyCode))
                    {
                        // 根据会议Id和手机号以及type获取会议信息
                        setPhoneValidate(true);
                    }
                    // 手机验证失败
                    else
                    {
                        Toast.show({
                            icon: 'fail',
                            content: "验证码错误",
                        });

                    }
                }} >确认</Button>]} style={{ marginTop: '3rem' }} ></FormButtonBox>] : null}

        </div>
    );
    //navigate('/OutsideMeetingCreate')
}

const TryRegisterMeetingUser = (userinfo) =>
{
    let validate = true;
    let errorMessage = "";
    if (!userinfo.name && validate)
    {
        errorMessage = '请填写正确的姓名';
        validate = false;
    }

    if (!userinfo.hospital && validate)
    {
        errorMessage = '请填写正确的医院';
        validate = false;
    }

    if (!userinfo.department && validate)
    {
        errorMessage = '请填写正确的科室';
        validate = false;
    }
    if (!userinfo.administrativePosition && validate)
    {
        errorMessage = '请填写正确的职务';
        validate = false;
    }
    
    if (!validate)
    {
        Toast.show({
            icon: 'fail',
            content: errorMessage,
        });
        return Promise.reject();
    }

    userinfo.source = 'meeting';
    userinfo.realm = 'techpool';
    let apiFunction = 'registerMeetingUser';
    if (userinfo.isRegister === '1')
    {
        apiFunction = 'updateDoctor';
    }

    return ApiPostWithOutAuth(apiFunction, userinfo);


}

/**
 * 尝试进行会议相关操作
 * @param {*} userinfo 
 * @param {*} jumpType 
 * @param {*} meetingId 
 */
const TryEnterMeeting = (userinfo, jumpType, meetingId) =>
{
    jumpType = Number(jumpType);
    ApiGetWithOutAuth('getMeetingInfoByQRCode', { phone: userinfo.phone, type: jumpType, id: meetingId }, false, null, true).then(response =>
    {
        switch (jumpType)
        {
            // 观众
            case 1: {
                // Toast.show({
                //     icon: 'loading',
                //     content: '加载中…',
                //     maskClickable: false,
                // })
                // 会议观看记录保存
                ApiPostWithOutAuth('logwatch', { ...userinfo, ...{ meetingId } }, true).then(() =>
                {
                    Toast.show({
                        content: '正在前往直播',
                        duration: 10000,
                        maskClickable: false,
                    });
                    setTimeout(() =>
                    {
                        window.location.href = response.data.result.txMeetingLiveAddr;
                    }, 2000);

                });
                break;
            }
            // 讲者
            case 2: {
                console.log("ApiPostWithOutAuth",userinfo);
                ApiPostWithOutAuth('logwatch', { ...userinfo, ...{ meetingId } }, true).then(() =>
                {
                    Toast.show({
                        content: '正在加载',
                        duration: 10000,
                        maskClickable: false,
                    });
                    setTimeout(() =>
                    {
                        window.location.href = `https://websites.ecache.com.cn/schedule/Participation.html?phone=${userinfo.phone}&type=${jumpType}&id=${meetingId}`;
                        // window.location.href = response.data.result.txMeetingLiveAddr;
                    }, 2000);
                });
                break;
                // window.location.href = `https://websites.ecache.com.cn/schedule/Participation.html?phone=${userinfo.phone}&type=${jumpType}&id=${meetingId}`;
                // window.location.href = `https://websites.ecache.com.cn/schedule/Participationtest.html?phone=${userinfo.phone}&type=${jumpType}&id=${meetingId}`;
                // break;
            }
            // 签到
            case 3: {
                // 保存签到记录
                ApiPostWithOutAuth('meetingSign', { ...userinfo, ...{ meetingId } }).then(() =>
                {
                    Toast.show({
                        icon: 'success',
                        content: '签到成功',
                        duration: 10000,
                        maskClickable: false
                    });
                    setTimeout(() =>
                    {
                        // 关闭页面
                        window.WeixinJSBridge && window.WeixinJSBridge.call('closeWindow');
                    }, 2000);
                });
                break;
            }
        }
    })
        .catch(error =>
        {
            // 提示错误
            let style = { fontSize: '1rem', fontWeight: '500', paddingLeft: '.5rem', paddingRight: '.5rem' };
            let p1 = <div style={{ ...style, ...{ paddingBottom: '.3rem' } }}>{error.response.data.error.message}</div>;
            Dialog.show({
                content: p1,
                closeOnAction: true,
                actions: [
                    {
                        text: '确认',
                        onClick: () =>
                        {
                            // 关闭页面
                            window.WeixinJSBridge && window.WeixinJSBridge.call('closeWindow');
                        }
                    }
                ]
            });
            // 关闭页面
        });
}

/**
 * 根据unionId获取用户
 * @param {*} unionId 
 * @returns 
 */
const CheckIsUserRegister = (unionId, setUserinfo) =>
{
    return ApiGetWithOutAuth('getIsRegisterByunionId', { unionId }).then(response =>
    {
        if (response.data.result && response.data.result.result)
        {
            var userinfo = { ...response.data.result.result, ...{ unionId } };
            // userinfo.name = userinfo.doctorName || '';
            setUserinfo(userinfo);
            return userinfo;
        }

        return null;
    });
}

var checkMobile = (value) =>
{
    //  验证手机号的正则表达式
    const regMobile =
        /^(0|86|17951)?(13[0-9]|15[012356789]|17[678]|18[0-9]|14[57])[0-9]{8}$/
    return regMobile.test(value)
}

let timer = null;
const countDownTime = 60;
const VerifyPhoneCodeDom = (props) =>
{
    const [time, setTime] = useState(0);
    const [disable, setDisable] = useState(false);

    useEffect(() =>
    {
        timer && clearInterval(timer);
        return () => timer && clearInterval(timer);
    }, []);

    useEffect(() =>
    {
        if (time === countDownTime)
        {
            timer = setInterval(() => setTime(time => --time), 1000);
        }
        else if (time === 0)
        {
            setDisable(false);
            clearInterval(timer)
        }
    }, [time])

    const getCode = () =>
    {
        if (!disable)
        {
            if (checkMobile(props.phone))
            {
                setDisable(true);
                ApiPostWithOutAuth('getPhoneCode', { id: props.phone })
                    .then((response) =>
                    {
                        console.log(props.phone)
                        phoneCodeVerifyHelper.saveTempInfo(props.phone, response.data.result);
                        //直接使用
                        setTime(countDownTime)
                    })
                    .catch(() =>
                    {
                        setDisable(false);
                    });
            }
            else
            {
                Toast.show({
                    icon: 'fail',
                    content: "手机号不正确",
                });

            }

        }


    }

    return [
        // <Button
        //     style={{
        //         '--text-color': 'var(--adm-color-primary)',
        //         '--border-color': '#fff',
        //         width: '10rem',
        //         paddingLeft: 0,
        //         paddingRight: 0
        //     }}
        //     disabled={time !== 0}
        //     size='small'
        //     onClick={getCode.bind()}
        // > {time === 0 ? '获取验证码' : `重新获取(${time})`}</Button>
        // ,
        <div style={props.style}>
            <div style={{ width: '11rem', fontSize: '1rem', display: 'table', pointerEvents: disable ? 'none' : 'auto', opacity: disable ? '0.5' : '1' }} onClick={getCode.bind()}>
                <a style={{ display: 'table-cell', verticalAlign: 'middle', textAlign: 'center' }}>{time === 0 ? '获取验证码' : `重新获取(${time})`}</a>
            </div>
        </div>


    ];
}

export default EnterMeeting;

