import moment from "moment";

const dateCalculate = (meetDatestr) =>
{
    const meetingDates = meetDatestr.split('-');
    const minDate = moment(meetingDates[0].trim(), 'MM月DD日').toDate();
    const maxDate = moment(meetingDates[1].trim(), 'MM月DD日').add(1, 'd').add(-1, 's').toDate();
    return {
        minDate,
        maxDate
    }
}

const meetingDateCalculate = (start, end) =>
{
    const minDate = moment(start.trim(), 'YYYY-MM-DD').toDate();
    const maxDate = moment(end.trim(), 'YYYY-MM-DD').add(1, 'd').add(-1, 's').toDate();
    return {
        minDate,
        maxDate
    }
}

const convterToString = (start, end) =>
{
    const startDate = moment(start.trim(), 'YYYY-MM-DD').format("MM月DD日");
    const endDate = moment(end.trim(), 'YYYY-MM-DD').format("MM月DD日");
    return `${startDate} - ${endDate}`
}

export { dateCalculate, meetingDateCalculate, convterToString };