import React from "react";
import style from "./style/mymeeting.module.css";

//my component
import MeetingList from "../component/meetingList";
import ListBar from "../component/listbar";

//lib
import { ApiGet, ApiPost } from "../lib/apihelper";
import { Dialog } from "antd-mobile";
import moment from "moment";
// import { logDOM } from "@testing-library/react";

const defaultTab = 1;
const defaultMeetingType = 2;

class MyMeeting extends React.Component {
  constructor(props) {
    super(props);
    const initMeetingType = props.defaultMeetingType || defaultMeetingType;
    this.state = {
      nowTab: defaultTab,
      meetingList: [],
      searchText: "",
      meetingType: initMeetingType,
    };
    this.getMeetingList(defaultTab, initMeetingType);
  }

  tabList = () => {
    return [
      { name: "未开始", tabId: 1 },
      { name: "进行中", tabId: 2 },
      { name: "已结束", tabId: 3 },
    ];
  };

  deleteMeeting = (meeting) => {
    return ApiPost("deleteMeeting", meeting);
  };

  /**
   * get banner list api
   * @returns
   */
  getMeetingList = (nowTab, meetingType, filterOut) => {
    const self = this;
    const params = {
      state: nowTab,
      filter: filterOut ? "" : self.state.searchText,
      maxResultCount: 1000,
      skipCount: 0,
      meetingType: meetingType || self.state.meetingType,
    };
    return ApiPost("myMeetingList", params).then((result) => {
      self.setState({
        meetingList: result.data.result.items,
      });
    });
  };

  showInsideMeetingDetail = (id) => {
    ApiPost("getMeetingById", { id }).then((result) => {
      const txMeeting = result.data.result.txMeeting;
      const meeting = result.data.result.meeting;
      Dialog.alert({
        content: (
          <div style={{ padding: ".5rem", wordBreak: "break-all" }}>
            <p>会议主题：{txMeeting.subject}</p>
            <p>
              会议时间：
              {moment.unix(txMeeting.startTime).format("YYYY-MM-DD HH:mm")}-
              {moment.unix(txMeeting.endTime).format("YYYY-MM-DD HH:mm")}
              (GMT+08:00)中国标准时间-北京
            </p>
            <p>
              会议链接：<a href={txMeeting.joinUrl}>{txMeeting.joinUrl}</a>
            </p>
            <p>腾讯会议：{txMeeting.txMeetingCode}</p>
            {txMeeting.pwd && <p>会议密码：{txMeeting.pwd}</p>}
            {txMeeting.hostKey && <p>主持人密码：{txMeeting.hostKey}</p>}
            {meeting.meetingNumber && <p>预计线上人数：{meeting.meetingNumber}</p>}
            
          </div>
        ),
        confirmText: "确认",
      });
    });
  };

  render() {
    return (
      <div className="mainBody">
        <ListBar
          style={{ margin: "1rem 0" }}
          nowTab={this.state.nowTab}
          tabList={this.tabList()}
          typeBtnShow={true}
          onTabChange={(tabId) => {
            this.setState({ nowTab: tabId });
            this.getMeetingList(tabId);
          }}
          onSearch={() => {
            this.getMeetingList(this.state.nowTab);
          }}
          onSearchTextChange={(value) => {
            this.setState({ searchText: value });
          }}
          onCancel={() => {
            this.setState({ searchText: "" });
            this.getMeetingList(
              this.state.nowTab,
              this.state.meetingType,
              true
            );
          }}
          meetingType={this.state.meetingType}
          onMeetingTypeChange={(meetingType) => {
            this.setState({ meetingType });
            this.getMeetingList(this.state.nowTab, meetingType);
          }}
        ></ListBar>
        <MeetingList
          meetingData={this.state.meetingList}
          canDelete={this.state.nowTab === 1 || this.state.nowTab === 3}
          onDelete={(value) => {
            this.deleteMeeting(value).then(() =>
              this.getMeetingList(this.state.nowTab)
            );
          }}
          isNav={() => this.state.meetingType === 2}
          itemClick={(value) => {
            if (this.state.meetingType === 2) {
              return `/OutsideMeetingView/${value.id}?isMyMeeting=${value.isMyMeeting}`;
            } else {
              this.showInsideMeetingDetail(value.id);
            }
          }}
        ></MeetingList>
      </div>
    );
  }
}

export default MyMeeting;
