

const setStorage = (key, value) =>
{
    localStorage.setItem(key, value);
}

const getStorage = (key) =>
{
    return localStorage.getItem(key);
}

const setStorageObj = (key, value) =>
{
    const objValue = JSON.stringify(value);
    setStorage(key, objValue);
}

const getStorageObj = (key) =>
{
    var val = getStorage(key);
    var dataobj = JSON.parse(val);
    return dataobj
}

const removeStorage = (key) =>
{
    localStorage.removeItem(key);
}

export { setStorage, getStorage, setStorageObj, getStorageObj, removeStorage };