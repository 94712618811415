import style from "./style/meetingReport.module.css";

import React, { useEffect, useState } from "react";
import getImage from "../lib/getImage";
import { useNavigate } from "react-router-dom";
import { Popup, Calendar, Button } from "antd-mobile";
import { ApiPost, ApiGet } from "../lib/apihelper";
import GetUserInfo from "../lib/userinfoHelper";
import { FormGroups, formDom } from "../component/formgroups";
import FormButtonBox from "../component/formbuttonbox";
import {
  CheckInput,
  FormInput,
  mainBoxBase,
  SwitchInput,
} from "../component/forminput";
import moment from "moment";

const getDefaultFromLayOut = (formValue) => {
  console.log(formValue);
  return {
    main: [
      {
        layOut: [
          {
            type: "UserDefinedInput",
            label: "会议场次",
            myInput: (
              <div className={style.infoBox}>
                <span>{formValue.meetingCount || 0}</span>
                <span>场</span>
              </div>
            ),
          },
          {
            type: "UserDefinedInput",
            label: "会议时长",
            myInput: (
              <div className={style.infoBox}>
                <span>{formValue.meetingDuration || "00:00:00"}</span>
              </div>
            ),
          },
        ],
      },
      {
        layOut: [
          {
            type: "UserDefinedInput",
            label: "会议讲者",
            myInput: (
              <div className={style.infoBox}>
                <span>{formValue.meetingSpeakerCount || 0}</span>
                <span>人/次</span>
              </div>
            ),
          },
          {
            type: "UserDefinedInput",
            label: "参会人次",
            myInput: (
              <div className={style.infoBox}>
                <span>{formValue.meetingJoinCount || 0}</span>
                <span>人/次</span>
              </div>
            ),
          },
          {
            type: "UserDefinedInput",
            label: "观众人次",
            myInput: (
              <div className={style.infoBox}>
                <span>{formValue.meetingviewerCount || 0}</span>
                <span>人/次</span>
              </div>
            ),
          },
        ],
      },
    ],
  };
};

const MeetingReport = (props) => {
  const [fromLayOut, setFormLayOut] = useState({ main: [] });
  const [formValue, setFormValue] = useState({});
  const [value, setValue] = useState({});
  const [calendarShow, setCalendarShow] = useState(false);
  const [selectDate, setSelectDate] = useState([moment(), moment()]);
  const [val, setVal] = useState([moment(), moment()]);

  useEffect(() => {
    setFormLayOut(getDefaultFromLayOut(formValue));
  }, [formValue]);
  useEffect(() => {
    ApiGet("getMeeingReport", {
      startTime: selectDate[0].format("YYYY-MM-DD"),
      endTime: selectDate[1].format("YYYY-MM-DD"),
    }).then((response) => {
      console.log(response.data.result);
      setFormValue(response.data.result);
    });
  }, [selectDate]);

  return (
    <div className="mainBody">
      {formDom(
        <div
          className={style.dataChooseBox}
          onClick={() => {
            setCalendarShow(true);
          }}
        >
          <div className={[style.dateChoose, style.left].join(" ")}>
            <div>开始日期</div>
            <div>{selectDate[0].format("YYYY-MM-DD")}</div>
          </div>
          <div className={[style.dateChoose, style.right].join(" ")}>
            <div>结束日期</div>
            <div>{selectDate[1].format("YYYY-MM-DD")}</div>
          </div>
        </div>,
        null,
        "请选择起止日期"
      )}
      <Popup
        visible={calendarShow}
        closeOnMaskClick={true}
        onClose={() => {
          setCalendarShow(false);
        }}
      >
        <Calendar
          style={{ marginTop: "1rem" }}
          selectionMode="range"
          value={val}
          onChange={(val) => {
            setVal(val);
          }}
        />
        <FormButtonBox
          buttons={[
            <Button
              style={{ width: "100%", fontWeight: "bold" }}
              onClick={() => {
                setCalendarShow(false);
              }}
            >
              关闭
            </Button>,
            <Button
              style={{ width: "100%", fontWeight: "bold" }}
              color="primary"
              onClick={() => {
                setSelectDate(val.map((x) => moment(x)));
                setCalendarShow(false);
              }}
            >
              确认
            </Button>,
          ]}
          style={{ marginTop: "3rem" }}
        ></FormButtonBox>
      </Popup>
      <FormGroups
        fromLayOut={fromLayOut}
        setFormLayOut={setFormLayOut}
        formVariable={value}
        setFormVariable={setValue}
      ></FormGroups>
    </div>
  );
  //navigate('/OutsideMeetingCreate')
};

export default MeetingReport;
