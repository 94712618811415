import logo from './logo.svg';
import { lazy, useState, useEffect } from 'react';
import './App.css';
import { HashRouter, Route, Routes, useParams, useNavigate, useLocation } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { setStorage, getStorage, removeStorage } from './lib/localtools';
import { ClearToken,isPc } from './lib/apihelper';


// Page component
import Index from './view/index';
import Empty from './view/empty';
import CreateMeetingType from './view/createMeetingType';
import InsideMeetingCreate from './view/insideMeetingCreate';
import OutsideMeetingCreate from './view/outsideMeetingCreate';
import OutsideMeetingDetail from './view/outsideMeetingDetail';
import OutsideMeetingView from './view/outsideMeetingView';
import MyMeeting from './view/mymeeting';
import { CustomerList } from './view/customerList';
import CustomerDetail from './view/customerDetail';
import EnterMeeting from './view/enterMeeting'
import MeetingReport from './view/meetingReport'
 

// import { ConfigProvider } from 'antd-mobile';
// import ReactDOM from 'react-dom/client';

// ReactDOM.render( <ConfigProvider {...{ breakpointWidths: { xs: 0, sm: 575, md: 768, lg: 992, xl: 1200, xxl: 1600 } }}> <App /> </ConfigProvider>, mountNode, );

//component
import Tabbar from './component/tabbar'
// alert(isPc())
function App (props)
{


  console.log(props);
  // let location = useLocation();
  const [state, setState] = useState(false);
  // let location = useLocation();
  // console.log("location", location);
  // console.log("useParams",useParams);
  return (
    <div className={`App ${isPc()?'maxWidth':''}`}  >
      <HashRouter>
        <MyRoute></MyRoute>
        {/* <SwitchTransition>
          <CSSTransition timeout={500} classNames="dg" unmountOnExit key={inProp} onEntered={() => { console.log("onEntered") }} onExited={() => { console.log("onExited") }} >
            <Routes>
              <Route path="/" element={<Index />}></Route>
              <Route path="/CreateMeetingType" element={<CreateMeetingType />}></Route>
              <Route path="/InsideMeetingCreate">
                <Route path=":id" element={<InsideMeetingCreate />}></Route>
                <Route path="" element={<InsideMeetingCreate />}></Route>
              </Route>
              <Route path="/OutsideMeetingCreate" element={<OutsideMeetingCreate />}></Route>
              <Route path="/OutsideMeetingDetail" element={<OutsideMeetingDetail />}></Route>
              <Route path="/MeetingList" element={<MyMeeting />}></Route>
              <Route path="/CustomerList" element={<CustomerList />}></Route>
              <Route path="/CustomerDetail" element={<CustomerDetail />}></Route>
              <Route path="/OutsideMeetingView" element={<OutsideMeetingView />}></Route>
              <Route path="*" element={<Empty />}></Route>
            </Routes>
          </CSSTransition>
        </SwitchTransition> */}
        {/* <SwitchTransition mode={'in-out'}>
          <CSSTransition
            key={state}
            classNames="dg"
            timeout={500}
          >
            <div className="button-container">
              <button onClick={() => setState(state => !state)}>
                {state ? "Hello, world!" : "Goodbye, world!"}
              </button>
            </div>
          </CSSTransition>
        </SwitchTransition> */}
        {window.location.hash.indexOf('/EnterMeeting/') === -1 && <Tabbar />}
      </HashRouter>
    </div>
  );
}

function MyRoute ()
{
  // let location = useLocation();
  // const [pathKey, setPathKey] = useState(location.pathname);
  // useEffect(() =>
  // {
  //   console.log("location", location.pathname);
  //   setPathKey(location.pathname);

  // },
  //   [location]);

  return (
    //<TransitionGroup style={{ height: '100vh' }}>
    //<CSSTransition timeout={600} classNames="dg" unmountOnExit key={pathKey} onEntered={() => { console.log("onEntered") }} onExited={() => { console.log("onExited") }} >
    <Routes>
       <Route path="/" element={<MyMeeting />}></Route>
      <Route path="/index" element={<Index />}></Route>
      <Route path="/CreateMeetingType" element={<CreateMeetingType />}></Route>
      <Route path="/InsideMeetingCreate">
        {/* <Route path=":id" element={<InsideMeetingCreate />}></Route> */}
        <Route path="" element={<InsideMeetingCreate />}></Route>
      </Route>
      <Route path="/OutsideMeetingCreate/:id/:name/:start/:end" element={<OutsideMeetingCreate />}></Route>
      <Route path="/OutsideMeetingDetail/:id" element={<OutsideMeetingDetail />}>
      </Route>
      <Route path="/MeetingList" element={<MyMeeting />}></Route>
      <Route path="/MeetingListInside" element={<MyMeeting defaultMeetingType={1} />}></Route>
      <Route path="/CustomerList" element={<CustomerList />}></Route>
      {/* <Route path="/CustomerList">
        <Route path=":meetingId" element={<CustomerList />}></Route>
        <Route path="" element={<CustomerList />}></Route>
      </Route> */}
      <Route path="/CustomerDetail/:id/:name/:code" element={<CustomerDetail />}></Route>
      <Route path="/OutsideMeetingView/:id" element={<OutsideMeetingView />}>
      </Route>
      <Route path="/EnterMeeting/:unionId/:meetingId/:jumpType" element={<EnterMeeting />}></Route>
      <Route path="/Login/:id" element={<Login />}></Route>
      <Route path="/meetingReport" element={<MeetingReport />} ></Route>
      <Route path="*" element={<Empty />}></Route>
    </Routes>
    //</CSSTransition>
    //</TransitionGroup>
  );
}

export default App;

const Login = (props) =>
{
  const { id } = useParams();
console.log("id", id);
  const navigate = useNavigate();
  const loginId = getStorage('LoginId');
  if (id !== loginId)
  {
    removeStorage('Login.Token');
    ClearToken();
    setStorage('LoginId', id);
  }

  useEffect(() =>
  {
    navigate('/MeetingList');
  }, []);
  return null;

}