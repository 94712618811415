import React from "react"
import style from './style/formbuttonbox.module.css'
import { Button } from 'antd-mobile'


const FormButtonBox = (props) =>
{
    const propsIsArray = Array.isArray(props.buttons);
    return (
        <div className={style.main} style={props.style}>
            {propsIsArray && props.buttons.map(item =>
                <div>
                    {item}
                </div>
            )}
            {!propsIsArray && (<div>{props.buttons}</div>)}
            {/* <div>
                <Button style={{ width:'100%' }} fill='outline' shape='rounded' color='primary' >上一步</Button>
            </div>
            <div>
                <Button style={{ width:'100%' }} shape='rounded' color='primary' >下一步</Button>
            </div> */}
        </div>
    )
}
export default FormButtonBox;