import React, { useState } from "react"
import style from './style/formGroups.module.css'
import getImage from '../lib/getImage';
import { useNavigate } from 'react-router-dom';
import { FormInput, CheckInput, SelectInput, DateInput, RadioInput, MultipleSelect, SwitchInput, UserDefinedInput } from '../component/forminput'

const defaultDemoGroupOption = [
    {
        title: "入会时自动保持禁音",
        layOut: [
            {
                type: "FormInput",
                label: "会议名称",
                keyWord: "meetingName",
                // placeholder:""
                // readOnly:false
            },
            {
                type: "CheckInput",
                label: "是否开启摄像头",
                keyWord: "OpenCarmar",
                // readOnly:false
            },
            {
                type: "SelectInput",
                label: "会议时长(分钟)",
                keyWord: "OpenCarmar",
                // placeholder:"",
                // valueFormat:"{value}XXX",
                options: [
                    [
                        { label: 1, value: 1 }
                    ]
                ],
                // readOnly:false
            },
            {
                type: 'DateInput',
                label: "会议日期",
                keyWord: "meetingDate",
                // placeholder:""
                // readOnly:false
            },
            {
                type: 'RadioInput',
                keyWord: "mictype",
                options: [
                    { label: "开启", value: 1 },
                    { label: "关闭", value: 2 },
                    { label: "入会6人以上自动静音", value: 3 },
                ]
                // readOnly:false
            },
            {
                type: 'MultipleSelect',
                label: "讨论嘉宾",
                keyWord: "Users",
                // placeholder:"",
                // valueFormat:"{value}XXX",
                options: [
                    [
                        { label: "Users1", value: 1 },
                        { label: "Users2", value: 2 },
                        { label: "Users3", value: 3 }
                    ]
                ],
                // readOnly:false
                //  userDefinedPicker:()=>{}
            },
            <h1>123</h1>
        ]
    }
]

const getDomByItemSetting = (item, formVariable, setFormVariable, isLast, fromLayOutSettings) =>
{
    let type = item.type || "";
    switch (type)
    {
        case "FormInput":
            {
                return <FormInput userDefinedStype={item.userDefinedStype} label={item.label} placeholder={item.placeholder} valueDetail={formVariable} setValueDetail={setFormVariable} keyWord={item.keyWord} readOnly={item.readOnly} isLast={isLast} onValueChange={item.onValueChange} fromLayOutSettings={fromLayOutSettings} ></FormInput>
            }
        case "CheckInput":
            {
                return <CheckInput label={item.label} valueDetail={formVariable} setValueDetail={setFormVariable} keyWord={item.keyWord} readOnly={item.readOnly} isLast={isLast} onValueChange={item.onValueChange} fromLayOutSettings={fromLayOutSettings}></CheckInput>;
            }
        case "SelectInput":
            {
                return <SelectInput label={item.label} placeholder={item.placeholder} valueDetail={formVariable} setValueDetail={setFormVariable} keyWord={item.keyWord} readOnly={item.readOnly} valueFormat={item.valueFormat} options={item.options} isLast={isLast} onValueChange={item.onValueChange} fromLayOutSettings={fromLayOutSettings}></SelectInput>;
            }
        case "DateInput":
            {
                return <DateInput label={item.label} placeholder={item.placeholder} valueDetail={formVariable} setValueDetail={setFormVariable} keyWord={item.keyWord} readOnly={item.readOnly} isLast={isLast} onValueChange={item.onValueChange} fromLayOutSettings={fromLayOutSettings} min={item.min} max={item.max}></DateInput>;
            }
        case "RadioInput":
            {
                return <RadioInput valueDetail={formVariable} setValueDetail={setFormVariable} keyWord={item.keyWord} readOnly={item.readOnly} options={item.options} isLast={isLast} onValueChange={item.onValueChange} fromLayOutSettings={fromLayOutSettings}></RadioInput>;
            }
        case "MultipleSelect":
            {
                return <MultipleSelect label={item.label} valueDetail={formVariable} setValueDetail={setFormVariable} keyWord={item.keyWord} readOnly={item.readOnly} options={item.options} userDefinedPicker={item.userDefinedPicker} isLast={isLast} onValueChange={item.onValueChange} fromLayOutSettings={fromLayOutSettings} onItemRemove={item.onItemRemove} ></MultipleSelect>
            }
        case "SwitchInput":
            {
                return <SwitchInput label={item.label} valueDetail={formVariable} setValueDetail={setFormVariable} keyWord={item.keyWord} readOnly={item.readOnly} isLast={isLast} onValueChange={item.onValueChange} fromLayOutSettings={fromLayOutSettings}></SwitchInput>
            }
        case "UserDefinedInput":
            {
                return <UserDefinedInput label={item.label} isLast={isLast} myInput={item.myInput}  ></UserDefinedInput>
            }
        default:
            {
                return item;
            }
    }
}

const FormGroups = (props) =>
{
    // const url = new URL(window.location.href);
    // const navigate = useNavigate();
    let formVariable = props.formVariable;
    let setFormVariable = props.setFormVariable;
    let fromLayOutSettings = {
        fromLayOut: props.fromLayOut,
        setFormLayOut: props.setFormLayOut
    }
    if (!props.fromLayOut || !props.setFormLayOut || !props.fromLayOut.main)
    {
        return null;
    }

    return props.fromLayOut.main.map((group) =>
    {
        let layOutShowCount = group.layOut.filter(x => x && (!x.hide || x.hide(formVariable))).length - 1;
        const inputDoms = group.layOut.filter(x => x).map((item, index) =>
        {
            return item.hide && !item.hide(formVariable) ? null : getDomByItemSetting(item, formVariable, setFormVariable, layOutShowCount === index, fromLayOutSettings);
        });
        return formDom(inputDoms, group.style, group.title);
    });
}

const formDom = (inputDoms, userDefindStyle, title) =>
{

    return (
        <div className={style.fromBase} style={userDefindStyle} >
            <div className={style.titleBox}>{title}</div>
            <div className={style.formGroup}>
                {inputDoms}
            </div>
        </div>
    )
}


export { FormGroups, formDom };