import React from "react";
import { ErrorBlock } from 'antd-mobile'


class Empty extends React.Component
{
    constructor (props)
    {
        super(props);
    }

    render ()
    {
        return (<ErrorBlock fullPage status='empty' />)
    }
}

export default Empty;