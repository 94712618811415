import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import { List, Image, Dialog } from 'antd-mobile';
import GetUserInfo from '../lib/userinfoHelper';

import style from './style/customerList.module.css';

import getImage from '../lib/getImage';
import { ApiGet, ApiPost } from '../lib/apihelper';

import ListBar from '../component/listbar';
import { DownOutline } from "antd-mobile-icons";



/**
 * tab settings
 * @returns 
 */
const tabList = (titleText) =>
{
    return [
        { name: titleText || '我的客户', tabId: 1 },
        // {name:'其他客户',tabId:2}
    ];
}

/**
 * main component
 * @param {*} props 
 * @returns 
 */
const CustomerList = (props) =>
{
    return (
        <div class="mainBody">
            <CustomerListDom></CustomerListDom>
        </div>
    )
}

 
/**
 * child component
 * @param {*} props 
 * @returns 
 */
const CustomerListDom = (props) =>
{
    const { meetingId } = props;
    const [customerDatas, setCustomerDatas] = useState([]);
    const navigate = useNavigate();
    useEffect(() =>
    {
        getCustomerList(setCustomerDatas, '', meetingId);
    }, []);


    return [
        ...[<ListBar
            style={{ margin: '1rem 0' }}
            nowTab={1}
            tabList={tabList(props.titleText)}
            onTabChange={(tabId) => { console.log(tabId) }}
            searchBtnHide={props.disableSerach}
            onSearch={(searchText) =>
            {
                getCustomerList(setCustomerDatas, searchText, meetingId);
            }}
            onCancel={() =>
            {
                getCustomerList(setCustomerDatas, '', meetingId);
            }}
        >
        </ListBar>],
        ...customerDatas.map((hos, index) =>
        {
            return (<div className={style.collapseBox}>
                <div className={style.main}>
                    <div className={style.mianbox} onClick={() =>
                    {
                        const tempCustomerDatas = customerDatas.concat();
                        tempCustomerDatas[index].showList = tempCustomerDatas[index].showList ? false : true;
                        setCustomerDatas(tempCustomerDatas);
                    }}>
                        <label className={style.inputLabel} >{hos.hospitalName}</label>
                        <div className={style.inputBox}>
                            ({hos.customers.length})
                        </div>
                         
                    </div>
                    {hos.showList ? listDom(props, hos.customers, navigate) : null}
                </div>
            </div>);
        })
    ]
}

/**
 * child component
 * @param {*} props 
 * @returns 
 */
const CustomerListDomSelect = (props) =>
{
    console.log('props',props);
    const { meetingId } = props;
    const [customerDatas, setCustomerDatas] = useState([]);
    const navigate = useNavigate();
    // console.log('meetingId',meetingId);
    useEffect(() =>
    {
        // console.log('meetingId222',meetingId);
        getCustomerList(setCustomerDatas, '', meetingId);
       
    }, []);


    return [
        // ...[<ListBar
        //     style={{ margin: '1rem 0' }}
        //     nowTab={1}
        //     tabList={tabList(props.titleText)}
        //     onTabChange={(tabId) => { console.log(tabId) }}
        //     searchBtnHide={props.disableSerach}
        //     onSearch={(searchText) =>
        //     {
        //         getCustomerList(setCustomerDatas, searchText, meetingId);
        //     }}
        //     onCancel={() =>
        //     {
        //         getCustomerList(setCustomerDatas, '', meetingId);
        //     }}
        // >
        // </ListBar>],
      
        ...customerDatas.map((hos, index) =>
        {
            return (<div className={style.collapseBox}>
                <div className={style.main}>
                    <div className={style.mianbox} onClick={() =>
                    {
                        const tempCustomerDatas = customerDatas.concat();
                        tempCustomerDatas[index].showList = tempCustomerDatas[index].showList ? false : true;
                        setCustomerDatas(tempCustomerDatas);
                    }}>
                        <label className={style.inputLabel} >{hos.hospitalName}</label>
                        <div className={style.inputBox}>
                            ({hos.customers.length})  <DownOutline/> 
                        </div>
                         
                    </div>
                    {hos.showList ? listDomSelect(props, hos.customers, navigate) : null}
                </div>
            </div>);
        })
    ]
}

export { CustomerList, CustomerListDom,CustomerListDomSelect };

/**
 * get customer list data from API
 * @param {*} setCustomerDatas 
 */
const getCustomerList = (setCustomerDatas, filter, meetingId) =>
{

    let httpFunction = ApiPost,
        api = 'queryDoctorInfo',
        hosPropName = 'companyHospital',
        resultPropName = 'doctorInfo',
        params;

    if (!meetingId)
    {
        const userInfo = GetUserInfo();
        params = { innerOuter: userInfo.employeeNo };
    }
    else if (filter)
    {
        params = { doctorName: filter };
    }
    else
    {
        httpFunction = ApiGet;
        api = 'getSpeakerByMeetingId';
        params = { meetingId };
        hosPropName = "institution";
        resultPropName = "speakers";
    }

    httpFunction(api, params).then((result) =>
    {
        
        setCustomerDatas(convertToHosDic(result.data.result.result[resultPropName], filter, hosPropName));
    });

}
/**
 * get customer list template dom 
 * @param {*} users 
 * @returns 
 */
const listDomSelect = (props, users, navigate) =>
{
 
    return (<List>
        {users.map(user => (
            <List.Item
                style={{ textAlign: 'left' }}
                key={user.id}
                prefix={
                    <Image
                        src={getImage(user.headImgUrl, true)}//user.headImgUrl
                        style={{ borderRadius: '2rem' }}
                        fit='cover'
                        width={'4rem'}
                        height={'4rem'}
                    />
                }
                onClick={() =>
                {
                    if (!user.headImgUrl)
                    {
                        emptyHeadImageAlert(user, navigate);
                        return;
                    } else if (props.state == null)
                    {
                        navigate(`/customerDetail/${user.id}/${user.name}/${user.code}`);
                    }
                    props.onItemClick && props.onItemClick(user)
                }}
            >
                <div style={{ margin: '1rem 0' }}>
                    <div style={{ fontSize: '1rem', fontWeight: '500' }}>
                        <span>{user.name}</span>
                    </div>
                    <div style={{ fontSize: '.8rem', fontWeight: '500',marginTop:'8px' }}>
                        <span className={user.isBind==="true"?style.hasBind:style.noBind}>{user.isBind==="true"?'已绑定':'未绑定'}</span>
                        <span className={user.headImgUrl?style.hasheadImgUrl:style.noheadImgUrl}>{user.headImgUrl?'有头像':'无头像'}</span>
                    </div>
                </div>
            </List.Item>
        ))}
    </List>);
}
 
const convertToHosDic = (customerList, filter, hosPropName) =>
{
    const result = [];

    customerList.forEach(item =>
    {
        const docName = item.doctorName || item.speakerName;
        if (filter && docName.indexOf(filter) === -1)
        {
            return;
        }
        let hos = result.find(x => x.hospitalName == item[hosPropName]);
        if (!hos)
        {
            hos = { hospitalName: item[hosPropName] };
            result.push(hos);
        }
        if (!hos.customers)
        {
            hos.customers = [];
        }

        hos.customers.push({
            "name": docName,
            "departmentNmae": item.department || item.speakerLevel,
            "headImgUrl": item.headImgUrl,
            "code": item.companyDoctorId || item.speakercrmCode,
            "phone": item.phone || item.mobile,
            "isBind":item.isBind,
            "id": item.id
        });
    });

    return result;
}

/**
 * get customer list template dom 
 * @param {*} users 
 * @returns 
 */
const listDom = (props, users, navigate) =>
{
    return (<List>
        {users.map(user => (
            <List.Item
                style={{ textAlign: 'left' }}
                key={user.id}
                prefix={
                    <Image
                        src={getImage(user.headImgUrl, true)}//user.headImgUrl
                        style={{ borderRadius: '2rem' }}
                        fit='cover'
                        width={'4rem'}
                        height={'4rem'}
                    />
                }
                onClick={() =>
                {
                    if (!user.headImgUrl)
                    {
                        emptyHeadImageAlert(user, navigate);
                        return;
                    } else if (props.state == null)
                    {
                        navigate(`/customerDetail/${user.id}/${user.name}/${user.code}`);
                    }
                    props.onItemClick && props.onItemClick(user)
                }}
            >
                <div style={{ margin: '1rem 0' }}>
                    <div style={{ fontSize: '1rem', fontWeight: '500' }}>
                        <span>{user.name}</span>
                    </div>
                    <div style={{ fontSize: '.8rem', fontWeight: '500' }}>
                        <span>{user.departmentNmae}</span>
                    </div>
                </div>
            </List.Item>
        ))}
    </List>);
}

const emptyHeadImageAlert = (user, navigate) =>
{
    let style = { fontSize: '1rem', fontWeight: '500', paddingLeft: '.5rem', paddingRight: '.5rem' };
    let confirmtext = <div style={{ ...style, ...{ paddingBottom: '.3rem' } }}>该客户暂无头像信息，需完善信息后方可参加会议</div>;
    Dialog.confirm({
        content: confirmtext,
        cancelText: <span style={{ color: '#8C8C8C' }}>取消</span>,
        confirmText: '去完善',
        onConfirm: () =>
        {
            // navigate('/customerDetail/' + user.id);
            navigate(`/customerDetail/${user.id}/${user.name}/${user.code}`);
        }
    });
}

