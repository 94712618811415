import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import style from "./style/outsideMeetingView.module.css";
import getImage from "../lib/getImage";
import { FormGroups } from "../component/formgroups";
import {
  CheckInput,
  FormInput,
  mainBoxBase,
  SwitchInput,
} from "../component/forminput";
import FormButtonBox from "../component/formbuttonbox";
import {
  Button,
  Dialog,
  FloatingBubble,
  Grid,
  Image,
  ImageViewer,
  Result,
  Toast,
} from "antd-mobile";
import { LinkOutline } from "antd-mobile-icons";
import ListBar from "../component/listbar";
import { ApiPost, isPc, getWidth } from "../lib/apihelper";
import moment from "moment";
import { dateCalculate } from "../lib/dateCalculate";
import { getStorageObj, removeStorage } from "../lib/localtools";
import { logDOM } from "@testing-library/react";

const defaultFromLayOut = (canEdit, maxDate, minDate) => {
  return {
    main: [
      {
        title: "",
        layOut: [
          {
            type: "FormInput",
            label: "会议编号",
            keyWord: "meetingNo",
            readOnly: true,
          },
        ],
      },
      {
        style: { marginTop: "2rem" },
        layOut: [
          {
            type: "FormInput",
            label: "会议主题",
            keyWord: "subject",
            readOnly: true,
          },
          {
            type: "FormInput",
            label: "会议时间",
            keyWord: "meetDate",
            readOnly: true,
          },
          {
            type: "DateInput",
            label: "开始时间",
            keyWord: "startTime",
            readOnly: !canEdit,
            min: minDate,
            max: maxDate,
            // placeholder:""
          },
          {
            type: "DateInput",
            label: "结束时间",
            keyWord: "endTime",
            readOnly: !canEdit,
            min: minDate,
            max: maxDate,
            // placeholder:""
          },
          {
            type: "SelectInput",
            label: "预计线上人数",
            keyWord: "meetingNumber",
            placeholder: "未填写预计上线人数",
            valueFormat: "{value}人",
            options: [
              [
                { label: "50", value: 50 },
                { label: "300", value: 300 },
                { label: "500", value: 500 },
              ],
            ],
            readOnly: !canEdit,
          },
          // {
          //     type: "CheckInput",
          //     label: "是否公开会议",
          //     keyWord: "isOpenMeeting",
          //     readOnly: !canEdit
          // },
          // {
          //     type: "CheckInput",
          //     label: "是否有线下客户参会",
          //     keyWord: "isDepartmentMeeting",
          //     readOnly: !canEdit
          // }
        ],
      },
    ],
  };
};

//会议数据
const getConferencedata = {
  main: [
    {
      layOut: [
        {
          type: "FormInput",
          label: "会议名称",
          keyWord: "subject",
          readOnly: true,
        },
        {
          type: "FormInput",
          label: "预定开始时间",
          keyWord: "startTime",
          readOnly: true,
        },
        {
          type: "FormInput",
          label: "预定结束时间",
          keyWord: "endTime",
          readOnly: true,
        },
        {
          type: "FormInput",
          label: "会议时长",
          keyWord: "totalMeetingDuration",
          readOnly: true,
        },
        {
          type: "FormInput",
          label: "会议室人数",
          keyWord: "totalCount",
          readOnly: true,
        },
        {
          type: "FormInput",
          label: "观众人数",
          keyWord: "watchCount",
          readOnly: true,
        },
        {
          type: "FormInput",
          label: "签到人数",
          keyWord: "signCount",
          readOnly: true,
        },
      ],
    },
  ],
};

//会议账号密码
const getMeetinginformation = {
  main: [
    {
      style: { marginTop: "2rem" },
      layOut: [
        {
          type: "FormInput",
          label: "会议号码",
          keyWord: "txMeetingCode",
          readOnly: true,
        },
        {
          type: "FormInput",
          label: "会议密码",
          keyWord: "pwd",
          readOnly: true,
        },
        {
          type: "FormInput",
          label: "主持人密码",
          keyWord: "hostKey",
          readOnly: true,
        },
      ],
    },
  ],
};

const getCustomersLayOut = (customers) => {
  // let nowIndex = 1;
  // let nowCustomerType = 1;
  return {
    main: [
      {
        layOut: customers.map((x, index) => {
          let title =
            x.role === 1
              ? "会议主席"
              : x.role === 2
              ? "会议主持"
              : x.role === 3
              ? "会议讲者"
              : x.role === 4
              ? "点评嘉宾"
              : "讨论嘉宾";
          // if (nowCustomerType !== x.role)
          // {
          //     nowIndex = 1
          // } else
          // {
          //     nowIndex++;
          // }
          if (x.role !== 3) {
            return mainBoxBase(
              customers.length - 1 == index,
              title,
              {},
              <span style={{ fontSize: "1rem" }}>{x.name}</span>
            );
          } else {
            return;
          }
        }),
      },
      // {
      //     layOut: [
      //         {
      //             type: "SwitchInput",
      //             label: "需要日程",
      //             keyWord: "userPoster",
      //             // readOnly:false
      //         }
      //     ]
      // }
    ],
  };
};

const getPostLayOut = (
  speakers,
  audience,
  viewerImage,
  speakerImage,
  signInfo
) => {
  const result = {
    main: [
      {
        layOut: [
          mainBoxBase(false, speakers, {}, null),
          <div style={{ textAlign: "center", padding: ".5rem 0" }}>
            <Image
              style={{ display: "inline-block" }}
              src={getImage(viewerImage)}
              className={style.qrcodeImg}
              onClick={() => {
                ImageViewer.show({ image: getImage(viewerImage) });
              }}
            />
          </div>,
        ],
      },
      {
        layOut: [
          mainBoxBase(false, audience, {}, null),

          <div style={{ textAlign: "center", padding: ".5rem 0" }}>
            <Image
              style={{ display: "inline-block" }}
              src={getImage(speakerImage)}
              className={style.qrcodeImg}
              onClick={() => {
                ImageViewer.show({ image: getImage(speakerImage) });
              }}
            />
          </div>,
        ],
      },
    ],
  };
  if (signInfo) {
    result.main.push({
      layOut: [
        mainBoxBase(false, signInfo.signQrCodeTitle, {}, null),

        <div style={{ textAlign: "center", padding: ".5rem 0" }}>
          <Image
            style={{ display: "inline-block" }}
            src={getImage(signInfo.signQrCodeImage)}
            className={style.qrcodeImg}
            onClick={() => {
              ImageViewer.show({ image: getImage(signInfo.signQrCodeImage) });
            }}
          />
        </div>,
      ],
    });
  }

  return result;
};

const performScienceFromLayOut = (
  performScience,
  participantsDetail,
  isDepartmentMeeting
) => {
  console.log('participantsDetail', participantsDetail);
  console.log('performScience', performScience);
  const girdHeadStyle = {
    backgroundColor: "#FE803C",
    color: "#FFF",
    padding: ".5rem 0",
    border: "1px solid #FE803C",
  };
  const gridCellStyle = {
    fontSize: "1rem",
    textAlign: "center",
    verticalAlign: "middle",
    backgroundColor: "#FFF",
    border: "1px solid #FFF",
    wordBreak: "break-all",
    padding: ".5rem .2rem",
  };
  const gridTitleStyle = {
    textAlign: "left",
    paddingBottom: ".6rem",
    paddingTop: ".9rem",
    color: "#8B8C8E",
    fontWeight: "700",
    fontSize: ".8rem",
  };

  const performSciencesLayOut = performScience.performSciences.map((item) => {
    const joinTime = moment(item.join_Time, "MM月DD日 HH:mm");
    const leftTime = moment(item.left_Time, "MM月DD日 HH:mm");
    const headImages = item.headImgUrl
      ? item.headImgUrl
          .split(",w_540,h_270,")
          .map((x) => x + (x.indexOf(",w_540,h_270") < 0 ? ",w_540,h_270" : ""))
      : [];
    const headImageDetail = headImages.map((x) =>
      x.replace(",w_540,h_270", ",w_1080,h_540")
    );
    // console.log("item.userName", item.userName);
    return {
      layOut: [
        <FormInput
          label={item.roleName}
          valueDetail={{ value: item.userName }}
          keyWord="value"
          readOnly={true}
        ></FormInput>,
        // <FormInput label='是否用预留手机参会' valueDetail={{ value: item.isPhope ? '是' : '否' }} keyWord='value'
        //     readOnly={true}></FormInput>,
        //<CheckInput label='是否用预留手机参会' isLast={false} readOnly={true} keyWord='value' valueDetail={{ value: `${leftTime.diff(joinTime, 'minutes')}` }} ></CheckInput>,
        item.headImgUrl ? (
          mainBoxBase(
            item.isPhope ? false : true,
            <div>
              {headImages.map((x, index) => (
                <Image
                  style={{ marginTop: ".5rem" }}
                  src={x}
                  onClick={() => {
                    ImageViewer.Multi.show({
                      defaultIndex: index,
                      images: headImageDetail,
                    });
                  }}
                ></Image>
              ))}
            </div>,
            {},
            <div style={{ fontSize: "1rem" }}>
              <p style={{ color: "#8B8C8E" }}>面部相似度</p>
              <p>{item.facialSimilarity}</p>
            </div>
          )
        ) : (
          <FormInput
            label="是否有面部相似度"
            valueDetail={{ value: "无" }}
            keyWord="value"
            readOnly={true}
            isLast={item.isPhope ? false : true}
          ></FormInput>
        ),
        // item.isPhope && <FormInput label='入会时间' valueDetail={{ value: item.join_Time }} keyWord='value'
        //     readOnly={true}></FormInput>,
        // item.isPhope && <FormInput label='离会时间' valueDetail={{ value: item.left_Time }} keyWord='value'
        //     readOnly={true}></FormInput>,
        // item.isPhope && <FormInput label='在线时长' valueDetail={{ value: `${leftTime.diff(joinTime, 'minutes')}分钟` }}
        //     keyWord='value'
        //     readOnly={true} isLast={true}></FormInput>,
      ],
    };
  });

  const videoUrl = performScience.reviewAddress
    ? [
        {
          layOut: [
            <div style={{ padding: "1rem 1.5rem" }}>
              <div
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  textAlign: "left",
                  width: "calc(100% - 4rem)",
                  paddingRight: "2rem",
                }}
              >
                <div className={style.videoUrlTitle}>会议回看地址</div>
                <div className={style.videoUriDetail}>
                  <a>{performScience.reviewAddress}</a>
                </div>
              </div>
              <div
                onClick={() => {
                  navigator.clipboard
                    .writeText(performScience.reviewAddress)
                    .then((success) => {
                      Toast.show("复制成功");
                    })
                    .catch((error) => {
                      Toast.show("不支持复制，请手动复制链接");
                    });
                }}
                className={style.copyLinkButton}
                style={{
                  display: "inline-block",
                  verticalAlign: "middle",
                  width: "2rem",
                }}
              >
                <a>复制</a>
              </div>
            </div>,
          ],
        },
      ]
    : [];

  const screenshotsImages = performScience.screenshots.map((x) => x.imgUrl);
  const speakerData = performScience.performSciences
    ? [
        {
          layOut: [
            <div
              style={{
                textAlign: "center",
                fontSize: "1rem",
                width: "90%",
                margin: "auto",
                marginTop: "2rem",
              }}
            >
              <div style={gridTitleStyle}>讲者数据</div>
              <Grid columns={5} gap={0}>
                <Grid.Item
                  style={{
                    ...girdHeadStyle,
                    ...{ borderRadius: "1rem 0 0 0" },
                  }}
                >
                  <div>姓名</div>
                </Grid.Item>
                <Grid.Item
                  style={{ ...girdHeadStyle, ...{ textAlign: "left" } }}
                >
                  <div>医院</div>
                </Grid.Item>
                <Grid.Item
                  style={{ ...girdHeadStyle, ...{ textAlign: "left" } }}
                >
                  <div>科室</div>
                </Grid.Item>
                <Grid.Item
                  style={{ ...girdHeadStyle, ...{ textAlign: "left" } }}
                >
                  <div>职务</div>
                </Grid.Item>
                <Grid.Item
                  style={{
                    ...girdHeadStyle,
                    ...{ borderRadius: "0  1rem 0 0 " ,textAlign: "left"},
                  }}
                >
                  <div>面部相似度</div>
                </Grid.Item>
              </Grid>
              {performScience.performSciences.map((x) => {
                return (
                  <Grid columns={5} gap={0} style={{ marginTop: "0" }}>
                    <Grid.Item style={{ ...gridCellStyle }}>
                      <div>{x.userName}</div>
                    </Grid.Item>
                    <Grid.Item
                      style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                    >
                      <div>{x.hospital}</div>
                    </Grid.Item>
                    <Grid.Item
                      style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                    >
                      <div>{x.department}</div>
                    </Grid.Item>
                    <Grid.Item
                      style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                    >
                      <div>{x.adminIstrativePosition?x.adminIstrativePosition:'无'}</div>
                    </Grid.Item>
                    <Grid.Item
                      style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                    >
                      <div>{x.facialSimilarity?x.facialSimilarity:'无'}</div>
                    </Grid.Item>
                  </Grid>
                );
              })}
            </div>,
          ],
        },
      ]
    : [];
  //腾讯会议数据
  // const qqMeetingData = participantsDetail.totalCount > 0 ? [
  //     {
  //         layOut: [<div style={{ textAlign: 'center', fontSize: '1rem', width: '90%', margin: 'auto', marginTop: '2rem' }}>
  //             <div style={gridTitleStyle}>腾讯会议数据</div>
  //             <Grid columns={4} gap={0}>
  //                 <Grid.Item style={{ ...girdHeadStyle, ...{ borderRadius: '1rem 0 0 0' } }}>
  //                     <div>姓名</div>
  //                 </Grid.Item>
  //                 <Grid.Item style={{ ...girdHeadStyle }}>
  //                     <div>联系方式</div>
  //                 </Grid.Item>
  //                 <Grid.Item style={{ ...girdHeadStyle }}>
  //                     <div>入会时间</div>
  //                 </Grid.Item>
  //                 <Grid.Item style={{ ...girdHeadStyle, ...{ borderRadius: '0 1rem 0 0' } }}>
  //                     <div>离开时间</div>
  //                 </Grid.Item>
  //             </Grid>
  //              {participantsDetail.queryMeetingParticipantsOutput.map(x => {
  //                 return (<Grid columns={4} gap={0} style={{ marginTop: '0' }}>
  //                     <Grid.Item style={{ ...gridCellStyle }}>
  //                         <div>{x.name}</div>
  //                     </Grid.Item>
  //                     <Grid.Item style={{ ...gridCellStyle, ...{ textAlign: 'left' } }}>
  //                         <div>{x.phone}</div>
  //                     </Grid.Item>
  //                     <Grid.Item style={{ ...gridCellStyle, ...{ textAlign: 'left' } }}>
  //                         <div>{x.joinTime}</div>
  //                     </Grid.Item>
  //                     <Grid.Item style={{ ...gridCellStyle, ...{ textAlign: 'left' } }}>
  //                         <div>{x.leftTime}</div>
  //                     </Grid.Item>
  //                 </Grid>)
  //             })} </div>]
  //     }
  // ] : [];
  //观看数据
  const customerListData =
    participantsDetail.watchCount > 0
      ? [
          {
            layOut: [
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  width: "90%",
                  margin: "auto",
                  marginTop: "2rem",
                }}
              >
                <div style={gridTitleStyle}>观看数据</div>
                <Grid columns={5} gap={0}>
                  <Grid.Item
                    style={{
                      ...girdHeadStyle,
                      ...{ borderRadius: "1rem 0 0 0" },
                    }}
                  >
                    <div>姓名</div>
                  </Grid.Item>
                  <Grid.Item
                    span={2}
                    style={{ ...girdHeadStyle, ...{ textAlign: "left" } }}
                  >
                    <div>医院</div>
                  </Grid.Item>
                  <Grid.Item
                  
                    style={{ ...girdHeadStyle, ...{ textAlign: "left" } }}
                  >
                    <div>科室</div>
                  </Grid.Item>

                  <Grid.Item
                    style={{
                      ...girdHeadStyle,
           
                      ...{ borderRadius: "0  1rem 0 0 ",textAlign: "left" },
                    }}
                  >
                    <div>职务</div>
                  </Grid.Item>
                </Grid>
                {participantsDetail.watchInfos.map((x) => {
                  return (
                    <Grid columns={5} gap={0} style={{ marginTop: "0" }}>
                      <Grid.Item style={{ ...gridCellStyle }}>
                        <div>{x.name}</div>
                      </Grid.Item>
                      <Grid.Item
                      span={2}
                        style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                      >
                        <div>{x.hospital}</div>
                      </Grid.Item>
                      <Grid.Item
                        style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                      >
                        <div>{x.department}</div>
                      </Grid.Item>
                      <Grid.Item
                        style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                      >
                        <div>{x.adminIstrativePosition?x.adminIstrativePosition:'无'}</div>
                      </Grid.Item>

                      {/* <Grid.Item style={{ ...gridCellStyle, ...{ textAlign: 'left' } }}>
                        <div>{x.phone}</div>
                    </Grid.Item>
                    <Grid.Item style={{ ...gridCellStyle, ...{ textAlign: 'left' } }}>
                        <div>{x.creationTime}</div>
                    </Grid.Item> */}
                    </Grid>
                  );
                })}
              </div>,
            ],
          },
        ]
      : [];
  //签到数据
  const signListData =
    isDepartmentMeeting && participantsDetail.signCount > 0
      ? [
          {
            layOut: [
              <div
                style={{
                  textAlign: "center",
                  fontSize: "1rem",
                  width: "90%",
                  margin: "auto",
                  marginTop: "2rem",
                }}
              >
                <div style={gridTitleStyle}>签到数据</div>
                <Grid columns={3} gap={0}>
                  <Grid.Item
                    style={{
                      ...girdHeadStyle,
                      ...{ borderRadius: "1rem 0 0 0" },
                    }}
                  >
                    <div>姓名</div>
                  </Grid.Item>
                  {/* <Grid.Item style={{ ...girdHeadStyle }}>
                        <div>联系方式</div>
                    </Grid.Item> */}
                  <Grid.Item style={{ ...girdHeadStyle }}>
                    <div>签到时间</div>
                  </Grid.Item>
                  <Grid.Item
                    style={{
                      ...girdHeadStyle,
                      ...{ borderRadius: "0 1rem 0 0" },
                    }}
                  >
                    <div>是否讲者</div>
                  </Grid.Item>
                </Grid>
                {participantsDetail.signInfos.map((x) => {
                  return (
                    <Grid columns={3} gap={0} style={{ marginTop: "0" }}>
                      <Grid.Item style={{ ...gridCellStyle }}>
                        <div>{x.name}</div>
                      </Grid.Item>
                      {/* <Grid.Item style={{ ...gridCellStyle, ...{ textAlign: 'left' } }}>
                            <div>{x.phone}</div>
                        </Grid.Item> */}
                      <Grid.Item
                        style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                      >
                        <div>{x.creationTime}</div>
                      </Grid.Item>
                      <Grid.Item
                        style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                      >
                        <div>{x.isSpeaker ? "是" : "否"}</div>
                      </Grid.Item>
                    </Grid>
                  );
                })}
              </div>,
            ],
          },
        ]
      : [];
  const screenshotsLayOut =
    performScience &&
    performScience.screenshots &&
    performScience.screenshots.length > 0
      ? [
          {
            layOut: [
              mainBoxBase(false, "会议截图", {
                labelStyle: { color: "#000", fontWeight: "800" },
              }),
              <div className={style.screenshotsImageBox}>
                {screenshotsImages.map((item, index) => (
                  <Image
                    lazy
                    width="45%"
                    src={item}
                    onClick={() => {
                      ImageViewer.Multi.show({
                        defaultIndex: index,
                        images: screenshotsImages.map((x) => x.split("?")[0]),
                      });
                    }}
                  />
                ))}
              </div>,
            ],
          },
        ]
      : [];
  return {
    main: [
      ...performSciencesLayOut,
      ...videoUrl,
      ...speakerData,
      ...customerListData,
      ...signListData,
      ...screenshotsLayOut,
    ],
    // main: [...videoUrl, ...speakerData, ...customerListData, ...signListData, ...screenshotsLayOut]
  };
};

const performMeetinImagScienceFromLayOut = (performScience) => {
  const girdHeadStyle = {
    backgroundColor: "#FE803C",
    color: "#FFF",
    padding: ".5rem 0",
    border: "1px solid #FE803C",
  };
  const gridCellStyle = {
    fontSize: "1rem",
    textAlign: "center",
    verticalAlign: "middle",
    backgroundColor: "#FFF",
    border: "1px solid #FFF",
    wordBreak: "break-all",
    padding: ".5rem .2rem",
  };
  const gridTitleStyle = {
    textAlign: "left",
    paddingBottom: ".5rem",
    color: "#8B8C8E",
    fontWeight: "700",
    fontSize: ".8rem",
  };

  const screenshotsImages = performScience.screenshots.map((x) => x.imgUrl);

  const screenshotsLayOut =
    performScience &&
    performScience.screenshots &&
    performScience.screenshots.length > 0
      ? [
          {
            layOut: [
              mainBoxBase(false, "会议截图", {
                labelStyle: { color: "#000", fontWeight: "800" },
              }),
              <div className={style.screenshotsImageBox}>
                {screenshotsImages.map((item, index) => (
                  <Image
                    lazy
                    width="45%"
                    src={item}
                    onClick={() => {
                      ImageViewer.Multi.show({
                        defaultIndex: index,
                        images: screenshotsImages.map((x) => x.split("?")[0]),
                      });
                    }}
                  />
                ))}
              </div>,
            ],
          },
        ]
      : [];
  return {
    main: [...screenshotsLayOut],
  };
};

const tabList = () => {
  return [
    { name: "基本信息", tabId: 1 },
    { name: "参会信息", tabId: 2 },
    { name: "履行材料", tabId: 3 },
    // { name: '会议数据', tabId: 4 }
  ];
};

const emptyDom = () => {
  return (
    <Result
      style={{ backgroundColor: "rgba(0,0,0,0)" }}
      status="warning"
      title="暂无数据"
      description="会议尚未结束，请于会议结束后24小时再来查看。"
    />
  );
};

const OutsideMeetingView = (props) => {
  const { id } = useParams();
  const [params] = useSearchParams();
  const isMyMeeting = params.get("isMyMeeting");
//   console.log("isMyMeeting", isMyMeeting);
  const [formVariable, setFormVariable] = useState({});
  const [fromLayOut, setFormLayOut] = useState({});
  //会议账号密码
  const [metting, setmetting] = useState(getMeetinginformation);
  //获取账号密码
  const [mettinguse, setmettinguse] = useState({});
  let dialog = null;
  //会议数据
  const [conferencedata, setconferencedata] = useState(getConferencedata);
  const [conferencedatalist, setconferencedatalist] = useState({ main: [] });

  const [postInfomation, setPostInfomation] = useState({});
  const [customersLayOut, setCustomersLayOut] = useState({ main: [] });
  const [performScienceLayOut, setPerformScienceLayOut] = useState({
    main: [],
  });
  const [
    performMeetinImagScienceFromLayOut,
    setperformMeetinImagScienceFromLayOut,
  ] = useState({ main: [] });
  const [nowTab, setNowTab] = useState(1);
  const [speakers, setspeakers] = useState({});
  const [audience, setaudience] = useState({});
  const [meetingParticipants, setMeetingParticipants] = useState(null);
  //服务器时间 默认当前时间 请求接口后自动更新为服务器时间
  let serverDate = new Date();
  //会议是否可以编辑，也会在第一次请求api时判断
  const [meetingCanEdit, setMeetingCanEdit] = useState(true);

  const [isDepartmentMeeting, setIsDepartmentMeeting] = useState(false);

  const [participantsDetail, setParticipantsDetail] = useState({});
  const [positionRight, setPositionRight] = useState("16px");
  const [windowWdith, setWindowWdith] = useState(document.body.clientWidth);

  useEffect(() => {
    let customers = [];
    Toast.show({
      icon: "loading",
      content: "加载中…",
      duration: 0,
      maskClickable: false,
    });
    Promise.all([
      ApiPost("getMeetingById", { id }, true),
      ApiPost("getPerformScience", { id }, true),
      ApiPost("meetingParticipants", { id }, true),
    ]).then((results) => {
      console.log("results", results);
      if (results[0].headers.date) {
        serverDate = new Date(results[0].headers.date);
      }

      results[2].data.result.totalCount =
        results[2].data.result.queryMeetingParticipantsOutput.length;
      results[2].data.result.watchCount =
        results[2].data.result.watchInfos.length;
      results[2].data.result.signCount =
        results[2].data.result.signInfos.length;
      setParticipantsDetail(results[2].data.result);
      setMeetingParticipants(results[2].data.result);
      setconferencedatalist(results[2].data.result);
      // results[0].data.result.meeting.startTime = '2021-09-10 03:00';
      const editJudge = moment(
        results[0].data.result.meeting.startTime
      ).isSameOrAfter(serverDate);
      setMeetingCanEdit(editJudge);

      //上级不能修改下级会议
      if (isMyMeeting === "false") {
        // console.log("isMyMeeting24442", isMyMeeting);
        // console.log("herere");
        setMeetingCanEdit(false);
      }
      const meetDate = results[0].data.result.meeting.meetDate;
      const { minDate, maxDate } = dateCalculate(meetDate);
      setFormLayOut(defaultFromLayOut(editJudge, maxDate, minDate));

      setFormVariable(results[0].data.result.meeting);
      //赋值
      setmettinguse(results[0].data.result.txMeeting);
      
      Array.isArray(results[0].data.result.meetingPlaybills) &&
        results[0].data.result.meetingPlaybills.forEach((item) => {
          customers = customers.concat(
            item.participants.map((x) => {
              return { ...x, ...{ role: item.role } };
            })
          );
        });
        console.log("customers",customers);
      setCustomersLayOut(getCustomersLayOut(customers));
      if (!editJudge) {
        console.log("hhhh",'333');
        setPerformScienceLayOut(
          performScienceFromLayOut(
            results[1].data.result,
            results[2].data.result,
            results[0].data.result.meeting.isDepartmentMeeting
          )
        );
      }

      let signInfo = null;
      setIsDepartmentMeeting(
        results[0].data.result.meeting.isDepartmentMeeting
      );
      if (results[0].data.result.meeting.isDepartmentMeeting) {
        signInfo = {
          signQrCodeTitle: "签到二维码",
          signQrCodeImage: results[0].data.result.signQrCode,
        };
      } else {
        getConferencedata.main[0].layOut.pop();
        setconferencedata(getConferencedata);
      }

      // setaudience(getPostLayOut('互动二维码', '讲者二维码', results[0].data.result.liveQrCode, results[0].data.result.speakerQrCode, signInfo))
      setaudience(
        getPostLayOut(
          "会议室二维码",
          "直播间二维码",
          results[0].data.result.speakerQrCode,
          results[0].data.result.liveQrCode,
          signInfo
        )
      );
      setspeakers(
        getPostLayOut(
          "观众海报",
          "讲者海报",
          results[0].data.result.viewerScheduleImage,
          results[0].data.result.speakerScheduleImage,
          signInfo
        )
      );

      Toast.clear();
    });
    if (isPc()) {
      // 监听
      window.addEventListener("resize", () =>
        setWindowWdith(document.body.clientWidth)
      );
    }

    // 销毁
    return () => {
      Dialog.clear();
      window.removeEventListener("resize", () => setWindowWdith(0));
    };
  }, []);
  /**
   * 兼容pc端FloatingBubble
   */
  useEffect(() => {}, [positionRight]);
  useEffect(() => {
    if (isPc()) {
      if (windowWdith > 850) {
        setPositionRight((windowWdith - 850) / 2 + 32 + "px");
      }
    }
  }, [windowWdith]);
  const navigate = useNavigate();

  return (
    <div className="mainBody">
      <ListBar
        style={{ margin: "1rem 0" }}
        nowTab={nowTab}
        tabList={tabList()}
        onTabChange={(tabId) => {
          setNowTab(tabId);
        }}
        searchBtnHide={true}
      ></ListBar>
      {(() => {
        switch (nowTab) {
          case 1: {
            const buttons = (
              <Button
                style={{ width: "50%" }}
                shape="rounded"
                color="primary"
                onClick={() => {
                  updateExternalMeeting(formVariable);
                }}
              >
                保存
              </Button>
            );
            const formButtonBox = meetingCanEdit && (
              <FormButtonBox
                buttons={buttons}
                style={{ marginTop: "3rem" }}
              ></FormButtonBox>
            );
            return [
              <FormGroups
                fromLayOut={fromLayOut}
                setFormLayOut={setFormLayOut}
                formVariable={formVariable}
                setFormVariable={setFormVariable}
              ></FormGroups>, //,anges
              <FormGroups
                fromLayOut={metting}
                setFormLayOut={setmetting}
                formVariable={mettinguse}
                setFormVariable={setmettinguse}
              ></FormGroups>,
              //时间判断 会议账号和会议密码
              //  new Date(formVariable.startTime) >= new Date() ?
              //   (<FormGroups fromLayOut={metting} setFormLayOut={setmetting}
              //        formVariable={mettinguse} setFormVariable={setmettinguse}></FormGroups>) : null
              formButtonBox,
            ];
          }
          case 2: {
            const buttons = (
              <Button
                style={{ width: "50%" }}
                shape="rounded"
                color="primary"
                onClick={() => {
                  const storageKey = `${id}_meeting_server`;
                  const storageValue = getStorageObj(storageKey);
                  new Promise((resolve) => {
                    if (storageValue) {
                      Dialog.show({
                        content: `检测到有上次编辑未保存的数据？`,
                        closeOnAction: true,
                        actions: [
                          [
                            {
                              key: "cancel",
                              text: "重新编辑",
                              onClick: () => {
                                resolve(true);
                              },
                            },
                            {
                              key: "ok",
                              text: "继续编辑",
                              onClick: () => {
                                resolve(false);
                              },
                            },
                          ],
                        ],
                      });
                    } else {
                      resolve(false);
                    }
                  }).then((clearCache) => {
                    if (clearCache) {
                      removeStorage(storageKey);
                    }
                    navigate(`/OutsideMeetingDetail/fromServer`, {
                      state: { serverId: id },
                    });
                  });
                }}
              >
                编辑日程
              </Button>
            );
            const formButtonBox = meetingCanEdit && (
              <FormButtonBox
                buttons={buttons}
                style={{ marginTop: "3rem" }}
              ></FormButtonBox>
            );
            return [
              // <FormGroups
              //   fromLayOut={customersLayOut}
              //   setFormLayOut={setCustomersLayOut}
              //   formVariable={postInfomation}
              //   setFormVariable={setPostInfomation}
              // ></FormGroups>,
              postInfomation.userPoster ? (
                <FormGroups
                  fromLayOut={speakers}
                  setFormLayOut={setspeakers}
                ></FormGroups>
              ) : (
                <FormGroups
                  fromLayOut={audience}
                  setFormLayOut={setaudience}
                ></FormGroups>
              ),
              // , formButtonBox
            ];
          }
          case 3: {
            const girdHeadStyle = {
              backgroundColor: "#FE803C",
              color: "#FFF",
              padding: ".5rem 0",
              border: "1px solid #FE803C",
            };
            const gridCellStyle = {
              fontSize: "1rem",
              textAlign: "center",
              verticalAlign: "middle",
              backgroundColor: "#FFF",
              border: "1px solid #FFF",
              wordBreak: "break-all",
              padding: ".5rem .2rem",
            };
            const gridTitleStyle = {
              textAlign: "left",
              paddingBottom: ".5rem",
              color: "#8B8C8E",
              fontWeight: "700",
              fontSize: ".8rem",
            };

            const hasValue =
              performScienceLayOut &&
              performScienceLayOut.main &&
              performScienceLayOut.main.length > 0;
            return hasValue
              ? [
                  <FormGroups
                    fromLayOut={conferencedata}
                    setFormLayOut={setconferencedata}
                    formVariable={conferencedatalist}
                    setFormVariable={setconferencedatalist}
                  ></FormGroups>,
                  <FormGroups
                    fromLayOut={performScienceLayOut}
                    setFormLayOut={setPerformScienceLayOut}
                  ></FormGroups>,
                  // <FormGroups fromLayOut={performMeetinImagScienceFromLayOut}
                  //     setFormLayOut={setPerformScienceLayOut}>
                  // </FormGroups>,
                  <FloatingBubble
                    axis="xy"
                    magnetic="x"
                    style={{
                      "--initial-position-bottom": "30vh",
                      "--initial-position-right": positionRight,
                      "--edge-distance": "1rem",
                      "--z-index": "1000",
                      "--size": "2.5rem",
                    }}
                    onClick={() => {
                      // const url = 'https://websites.ecache.com.cn/schedule/material.html?id=' + id;
                      const url =
                        "https://websites.ecache.com.cn/schedule/material.html?id=" +
                        id;
                      dialog = Dialog.show({
                        title: "电脑端查看链接",
                        content: (
                          <div
                            style={{
                              padding: "1rem 0",
                              fontSize: "1rem",
                              wordBreak: "break-all",
                            }}
                          >
                            <a href={url}>{url}</a>
                          </div>
                        ),
                        actions: [
                          [
                            {
                              text: "复制链接",
                              key: "copy",
                              onClick: () => {
                                navigator.clipboard
                                  .writeText(url)
                                  .then((success) => {
                                    Toast.show("复制成功");
                                  })
                                  .catch((error) => {
                                    Toast.show("不支持复制，请手动复制链接");
                                  });
                              },
                            },
                            {
                              text: "关闭",
                              key: "close",
                              onClick: () => {
                                dialog.close();
                              },
                            },
                          ],
                        ],
                      });
                    }}
                  >
                    <LinkOutline fontSize="2rem" />
                  </FloatingBubble>,
                ]
              : emptyDom();
          }
          case 4: {
            const girdHeadStyle = {
              backgroundColor: "#FE803C",
              color: "#FFF",
              padding: ".5rem 0",
              border: "1px solid #FE803C",
            };
            const gridCellStyle = {
              fontSize: "1rem",
              textAlign: "center",
              verticalAlign: "middle",
              backgroundColor: "#FFF",
              border: "1px solid #FFF",
              wordBreak: "break-all",
              padding: ".5rem .2rem",
            };
            const gridTitleStyle = {
              textAlign: "left",
              paddingBottom: ".5rem",
              color: "#8B8C8E",
              fontWeight: "700",
              fontSize: ".8rem",
            };

            return [
              <FormGroups
                fromLayOut={conferencedata}
                setFormLayOut={setconferencedata}
                formVariable={conferencedatalist}
                setFormVariable={setconferencedatalist}
              ></FormGroups>,
              participantsDetail.totalCount > 0 && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    width: "90%",
                    margin: "auto",
                    marginTop: "2rem",
                  }}
                >
                  <div style={gridTitleStyle}>腾讯会议数据</div>
                  <Grid columns={3} gap={0}>
                    <Grid.Item
                      style={{
                        ...girdHeadStyle,
                        ...{ borderRadius: "1rem 0 0 0" },
                      }}
                    >
                      <div>姓名</div>
                    </Grid.Item>
                    {/* <Grid.Item style={{ ...girdHeadStyle }}>
                                        <div>联系方式</div>
                                    </Grid.Item> */}
                    <Grid.Item style={{ ...girdHeadStyle }}>
                      <div>入会时间</div>
                    </Grid.Item>
                    <Grid.Item
                      style={{
                        ...girdHeadStyle,
                        ...{ borderRadius: "0 1rem 0 0" },
                      }}
                    >
                      <div>离开时间</div>
                    </Grid.Item>
                  </Grid>
                  {meetingParticipants.queryMeetingParticipantsOutput.map(
                    (x) => {
                      return (
                        <Grid columns={3} gap={0} style={{ marginTop: "0" }}>
                          <Grid.Item style={{ ...gridCellStyle }}>
                            <div>{x.name}</div>
                          </Grid.Item>
                          {/* <Grid.Item style={{ ...gridCellStyle, ...{ textAlign: 'left' } }}>
                                            <div>{x.phone}</div>
                                        </Grid.Item> */}
                          <Grid.Item
                            style={{
                              ...gridCellStyle,
                              ...{ textAlign: "left" },
                            }}
                          >
                            <div>{x.joinTime}</div>
                          </Grid.Item>
                          <Grid.Item
                            style={{
                              ...gridCellStyle,
                              ...{ textAlign: "left" },
                            }}
                          >
                            <div>{x.leftTime}</div>
                          </Grid.Item>
                        </Grid>
                      );
                    }
                  )}
                </div>
              ),
              participantsDetail.watchCount > 0 && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    width: "90%",
                    margin: "auto",
                    marginTop: "2rem",
                  }}
                >
                  <div style={gridTitleStyle}>观看数据</div>
                  <Grid columns={2} gap={0}>
                    <Grid.Item
                      style={{
                        ...girdHeadStyle,
                        ...{ borderRadius: "1rem 0 0 0" },
                      }}
                    >
                      <div>姓名</div>
                    </Grid.Item>
                    {/* <Grid.Item style={{ ...girdHeadStyle }}>
                                        <div>联系方式</div>
                                    </Grid.Item> */}
                    <Grid.Item style={{ ...girdHeadStyle }}>
                      <div>观看时间</div>
                    </Grid.Item>
                  </Grid>
                  {meetingParticipants.watchInfos.map((x) => {
                    return (
                      <Grid columns={2} gap={0} style={{ marginTop: "0" }}>
                        <Grid.Item style={{ ...gridCellStyle }}>
                          <div>{x.name}</div>
                        </Grid.Item>
                        {/* <Grid.Item style={{ ...gridCellStyle, ...{ textAlign: 'left' } }}>
                                            <div>{x.phone}</div>
                                        </Grid.Item> */}
                        <Grid.Item
                          style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                        >
                          <div>{x.creationTime}</div>
                        </Grid.Item>
                      </Grid>
                    );
                  })}
                </div>
              ),
              isDepartmentMeeting && participantsDetail.signCount > 0 && (
                <div
                  style={{
                    textAlign: "center",
                    fontSize: "1rem",
                    width: "90%",
                    margin: "auto",
                    marginTop: "2rem",
                  }}
                >
                  <div style={gridTitleStyle}>签到数据</div>
                  <Grid columns={3} gap={0}>
                    <Grid.Item
                      style={{
                        ...girdHeadStyle,
                        ...{ borderRadius: "1rem 0 0 0" },
                      }}
                    >
                      <div>姓名</div>
                    </Grid.Item>
                    {/* <Grid.Item style={{ ...girdHeadStyle }}>
                                        <div>联系方式</div>
                                    </Grid.Item> */}
                    <Grid.Item style={{ ...girdHeadStyle }}>
                      <div>签到时间</div>
                    </Grid.Item>
                    <Grid.Item
                      style={{
                        ...girdHeadStyle,
                        ...{ borderRadius: "0 1rem 0 0" },
                      }}
                    >
                      <div>是否讲者</div>
                    </Grid.Item>
                  </Grid>
                  {meetingParticipants.signInfos.map((x) => {
                    return (
                      <Grid columns={3} gap={0} style={{ marginTop: "0" }}>
                        <Grid.Item style={{ ...gridCellStyle }}>
                          <div>{x.name}</div>
                        </Grid.Item>
                        {/* <Grid.Item style={{ ...gridCellStyle, ...{ textAlign: 'left' } }}>
                                            <div>{x.phone}</div>
                                        </Grid.Item> */}
                        <Grid.Item
                          style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                        >
                          <div>{x.creationTime}</div>
                        </Grid.Item>
                        <Grid.Item
                          style={{ ...gridCellStyle, ...{ textAlign: "left" } }}
                        >
                          <div>{x.isSpeaker ? "是" : "否"}</div>
                        </Grid.Item>
                      </Grid>
                    );
                  })}
                </div>
              ),
            ];

            // return meetingParticipants ?
            //     <div style={{textAlign: 'left', fontSize: '1rem', paddingLeft: '1rem'}}>
            //         <p>会议名称：{meetingParticipants.subject}</p>
            //         <p>开始时间：{meetingParticipants.startTime}</p>
            //         <p>结束时间：{meetingParticipants.endTime}</p>
            //         <p>会议时长：{meetingParticipants.totalMeetingDuration}</p>
            //         <p>出席人数：{meetingParticipants.totalCount}</p>
            //         <Grid columns={4} gap={2}>
            //             <Grid.Item style={{border: '1px solid #CDCDCD'}}>
            //                 <div>姓名</div>
            //             </Grid.Item>
            //             <Grid.Item style={{border: '1px solid #CDCDCD'}}>
            //                 <div>联系方式</div>
            //             </Grid.Item>
            //             <Grid.Item style={{border: '1px solid #CDCDCD'}}>
            //                 <div>入会时间</div>
            //             </Grid.Item>
            //             <Grid.Item style={{border: '1px solid #CDCDCD'}}>
            //                 <div>离开时间</div>
            //             </Grid.Item>
            //         </Grid>
            //         {meetingParticipants.queryMeetingParticipantsOutput.map(x => {
            //             return (<Grid columns={4} gap={2} style={{marginTop: '.5rem'}}>
            //                 <Grid.Item style={{
            //                     border: '1px solid #CDCDCD',
            //                     fontSize: '.9rem',
            //                     textAlign: 'center'
            //                 }}>
            //                     <div>{x.name}</div>
            //                 </Grid.Item>
            //                 <Grid.Item style={{
            //                     border: '1px solid #CDCDCD',
            //                     fontSize: '.9rem',
            //                     textAlign: 'center'
            //                 }}>
            //                     <div>{x.phone}</div>
            //                 </Grid.Item>
            //                 <Grid.Item style={{
            //                     border: '1px solid #CDCDCD',
            //                     fontSize: '.9rem',
            //                     textAlign: 'center'
            //                 }}>
            //                     <div>{x.joinTime}</div>
            //                 </Grid.Item>
            //                 <Grid.Item style={{
            //                     border: '1px solid #CDCDCD',
            //                     fontSize: '.9rem',
            //                     textAlign: 'center'
            //                 }}>
            //                     <div>{x.leftTime}</div>
            //                 </Grid.Item>
            //             </Grid>)
            //         })}
            //     </div> : emptyDom();
          }
        }
      })()}
    </div>
  );
};

const getLayOutGroup = (layOut, groupIndex) => {
  return layOut.main[groupIndex];
};

const getLayOutItem = (layOut, groupIndex, itemIndex) => {
  return getLayOutGroup(layOut, groupIndex).layOut[itemIndex];
};

const updateExternalMeeting = (meeting) => {
  const {
    startTime,
    endTime,
    meetingNumber,
    isOpenMeeting,
    isDepartmentMeeting,
    id,
  } = meeting;
  ApiPost("updateExternalMeeting", {
    startTime,
    endTime,
    meetingNumber,
    isOpenMeeting,
    isDepartmentMeeting,
    id,
  });
};

export default OutsideMeetingView;
