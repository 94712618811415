import React, { useState } from "react"
import style from './style/listbar.module.css'
import getImage from '../lib/getImage';
import { useNavigate } from 'react-router-dom';
import { SearchBar } from 'antd-mobile';
import { Transition } from 'react-transition-group'

const duration = 300;

const defaultStyle = {
    transition: `opacity ${duration}ms ease-in-out`,
    opacity: 0,
}

const transitionStyles = {
    entering: { opacity: 1, transition: '300ms', transform: 'translateX(0%)' },
    entered: { opacity: 1, transition: '300ms', transform: 'translateX(0%)' },
    exiting: { opacity: 0, height: '0', transition: '300ms', transform: 'translateX(100%)' },
    exited: { opacity: 0, height: '0', transition: '300ms', transform: 'translateX(100%)' },
};

const ListBar = (props) =>
{
    const navigate = useNavigate();
    const [searchShow, setSearchShow] = useState(false);
    const [searchText, setSearchText] = useState(props.defaultText || '');
    // const [meetingType, setMeetingType] = useState(props.meetingType);
    return (
        <div className={style.main} style={props.style}>
            <div class={style.tabInLine}>
                <div className={style.tabBox}>
                    {props.tabList.map(item =>
                    {
                        let actLine = null;
                        let className = style.tabItem;
                        if (props.nowTab === item.tabId)
                        {
                            actLine = <div className={style.actLine}></div>;
                            className += ` ${style.actTabItem}`
                        }
                        return (<div className={className} onClick={() =>
                        {
                            props.onTabChange && props.onTabChange(item.tabId, item);
                        }}>
                            <span>{item.name}</span>
                            {actLine}
                        </div>)
                    })}
                </div>
                <div className={style.searchBox}>
                    {props.typeBtnShow ? (
                        <div onClick={() =>
                        {
                            props.onMeetingTypeChange && props.onMeetingTypeChange(props.meetingType == 1 ? 2 : 1)
                        }} className={style.meetingTypeButton}>
                            <img className={style.typeIcon} src={getImage(`${props.meetingType == 2 ? 'in' : 'out'}sideicon.svg`)} />
                            <span className={style.typeIcon}>{props.meetingType == 2 ? '内部会议' : '外部会议'}</span>
                        </div>
                    ) : null}
                    {props.searchBtnHide ? null : (
                        <div onClick={() => { setSearchShow(!searchShow); }} className={style.searchButton}>
                            <img className={style.searchitem} src={getImage('searchItem.svg')} />
                        </div>
                    )}
                </div>
            </div>
            {props.searchBtnHide ? null : <Transition in={searchShow} timeout={duration}>
                {state => (
                    <div style={{
                        ...defaultStyle,
                        ...transitionStyles[state],
                        ...{ padding: '.5rem 1rem' }
                    }}>
                        <SearchBar
                            value={searchText}
                            onChange={(value) => { setSearchText(value); props.onSearchTextChange && props.onSearchTextChange(value) }}
                            onSearch={() => { props.onSearch && props.onSearch(searchText) }}
                            placeholder='请输入需要搜索的内容'
                            showCancelButton={() => true}
                            style={{ '--background': '#ffffff' }}
                            clearable={false}
                            onCancel={() =>
                            {
                                props.onCancel && props.onCancel();
                            }}
                        />
                    </div>
                )}
            </Transition>}

        </div>
    )
}
export default ListBar;