import { getStorage } from './localtools';

const getUserInfo = () =>
{
    var role = getStorage('Login.Role');
    var name = getStorage('Login.Name');
    var employeeNo = getStorage('Login.EmployeeNo');
    var bu = getStorage('Login.Bu');
    return { role, name, employeeNo, bu };
}

export default getUserInfo;