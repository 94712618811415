import React from "react"
import style from './style/meetingList.module.css'
import getImage from '../lib/getImage';
import MeetingItem from "./meetingItem";
import { Empty, SwipeAction, Dialog } from 'antd-mobile';
import { DeleteOutline } from 'antd-mobile-icons'


const MeetingList = (props) =>
{
    return (
        <div className={style.main}>
            {props.meetingData.map((item) =>
            {
                return (
                    <SwipeAction
                        style={{ '--background': 'rgba(0,0,0,0)' }}
                        rightActions={getDeleteAction(props, item)}
                        onAction={() =>
                        {
                            Dialog.confirm({
                                content: '是否确认删除',
                                onConfirm: async () =>
                                {
                                    props.onDelete && props.onDelete(item);
                                },
                            })

                        }}
                    >
                        <MeetingItem isNav={props.isNav} meetingDetail={item} itemClick={props.itemClick}  ></MeetingItem>
                    </SwipeAction>
                )
            })}
            {props.meetingData.length > 0 ? null : (<Empty
                style={{ paddingTop: '50%' }}
                imageStyle={{ width: '50%' }}
                description='暂无会议信息'
            />)}
        </div>
    )
}

const getDeleteAction = (props,item) =>
{
    return props.canDelete  && item.isMyMeeting== true? [
        {
            key: 'delete',
            text: <DeleteOutline color='var(--adm-color-primary)' fontSize='1.5rem' />,
            color: 'rgba(0,0,0,0)'
        },
    ] : [];
}


export default MeetingList;