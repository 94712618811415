import React, { useState } from "react";
import getImage from "../lib/getImage";
import { useNavigate } from "react-router-dom";
import { Dialog, List, Radio } from "antd-mobile";
import {  ApiGet, isPc } from "../lib/apihelper";
import GetUserInfo from "../lib/userinfoHelper";
import moment from "moment";
import style from  './style/createMeetingType.module.css';
let tempMeetingList = [];

const CreateMeetingType = (props) => {
 
  const navigate = useNavigate();
  const [rowId, setRowId] = useState();
  const [visible, setVisible] = useState(false);
  const [meetingList, setMeetingList] = useState([]);
  /**
   * 处理外部会议点击
   */
  const handleOutMeetingClick = () => {
    GetMeeting().then((data) => {
      if (data && data.length > 0) {
        setMeetingList(data);
        setVisible(true);
      } else EmptyAlert();
    });
  };
  return (
    <div className="mainBody">
      <div
         className={style.divbox}
      >
        <img
          onClick={() => {
            navigate("/InsideMeetingCreate");
          }}
          src={getImage("createMeetingIn.png")}
          className={style.imgStyle}
        ></img>
        <img
          onClick={handleOutMeetingClick}
          src={getImage("createMeetingOut.png")}
          className={style.imgStyle}
        ></img>
      </div>
      {ListConfirm(visible, setVisible, rowId, setRowId, meetingList, navigate)}
    </div>
  );
  //navigate('/OutsideMeetingCreate')
};

const GetMeeting = () => {
  const userInfo = GetUserInfo();
  return ApiGet("getMeetingByCRM", {
    startTime: moment().format("YYYY-MM-DD"),
    endTime: "2099-01-01",
    creator: userInfo.name,
  }).then((res) => {
    tempMeetingList = res.data.result.result.meetingInfo;
    return tempMeetingList.map((x) => {
      return {
        id: x.meetingId,
        title: x.meetingName,
      };
    });
  });
};

const ListConfirm = (
  visible,
  setVisible,
  rowId,
  setRowId,
  meetingList,
  navigate
) => {
    let dialogcss ={}
    if(isPc()){
         dialogcss = { width: "75vw" ,maxWidth:'750px' }
    }else{
         dialogcss = { width: "75vw" }
    }


  const listDom = (
    <div style={{ maxHeight: "30vh" }}>
      <List mode="card">
        <Radio.Group
          value={rowId}
          onChange={(value) => {
            setRowId(value);
          }}
        >
          {meetingList.map((item) => (
            <List.Item extra={<Radio block value={item.id}></Radio>}>
              <div
                style={{ fontSize: "1rem", textAlign: "left", width: "100%" }}
              >
                {item.title}
              </div>
            </List.Item>
          ))}
        </Radio.Group>
      </List>
    </div>
  );
  return (
    <Dialog
      visible={visible}
      content={listDom}
      bodyStyle={dialogcss}
      title="请选择需要填写的会议信息"
      actions={[
        [
          {
            key: "cancel",
            text: "取消",
            onClick: () => {
              setVisible(false);
            },
          },
          {
            key: "confirm",
            text: "确认",
            onClick: () => {
              setVisible(false);
              const meetingDetail = tempMeetingList.find(
                (x) => x.meetingId == rowId
              );
              if (meetingDetail) {
                navigate(
                  `/OutsideMeetingCreate/${rowId}/${meetingDetail.meetingName}/${meetingDetail.startDate}/${meetingDetail.endDate}`
                );
              }
            },
          },
        ],
      ]}
    />
  );

  // Dialog.confirm({
  //     bodyStyle:{ width:'75vw' },
  //     title: '请选择需要填写的会议信息',
  //     content: listDom,
  //     cancelText:'关闭',
  //     onConfirm: () => {
  //         console.log(rowId);
  //         navigate('/OutsideMeetingCreate?id='+rowId);
  //         setRowId();
  //     }
  // })
};

const EmptyAlert = () => {
  let style = {
    fontSize: "1rem",
    fontWeight: "500",
    paddingLeft: ".5rem",
    paddingRight: ".5rem",
  };
  let p1 = (
    <div style={{ ...style, ...{ paddingBottom: ".3rem" } }}>
      您暂时没有需要填写会议室信息的会议。请先在公司CRM系统中申请会议。
    </div>
  );
  let p2 = (
    <div style={{ ...style, ...{ paddingTop: ".3rem" } }}>
      如果之前已经填写过会议室信息，需要完善其他会议信息，请在我的会议中查找。
    </div>
  );
  Dialog.show({
    content: [p1, p2],
    closeOnAction: true,
    actions: [
      {
        text: "确认",
      },
    ],
  });
};

export default CreateMeetingType;
