import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formDom, FormGroups } from "../component/formgroups";
import { FormInput, MultipleSelect, SelectInput } from "../component/forminput";
import FormButtonBox from "../component/formbuttonbox";
import { Button, Dialog, NavBar, Picker, Popup, Toast } from "antd-mobile";
import {
  CloseCircleOutline,
  DownOutline,
  UndoOutline,
  UpOutline,
} from "antd-mobile-icons";
import moment from "moment";
import GetUserInfo from "../lib/userinfoHelper";

import MeetingStep from "../component/meetingStep";
import { CustomerListDom, CustomerListDomSelect } from "./customerList";
import { getStorageObj, removeStorage, setStorageObj } from "../lib/localtools";
import { ApiGet, ApiPost } from "../lib/apihelper";

const defaultFromLayOut = (
  setNowCustomerType,
  setCustomerShow,
  setFormVariable
) => {
  const customersLayOutSettings = [
    { label: "会议主席", keyWord: "1" },
    { label: "会议主持", keyWord: "2" },
    { label: "会议讲者", keyWord: "3" },
    { label: "点评嘉宾", keyWord: "4" },
    { label: "讨论嘉宾", keyWord: "5" },
  ];

  const customersLayOut = customersLayOutSettings.map((item) => {
    return {
      type: "MultipleSelect",
      label: item.label,
      keyWord: item.keyWord,
      onItemRemove: (value, index, formVariable) => {
        const tempFormVariable = { ...formVariable };
        tempFormVariable[item.keyWord].splice(index, 1);
        setFormVariable(tempFormVariable);
      },
      userDefinedPicker: () => {
        setNowCustomerType(item.keyWord);
        setCustomerShow(true);
      },
    };
  });

  return {
    main: [
      {
        title: "",
        layOut: customersLayOut,
      },
      {
        title: "",
        layOut: [
          {
            type: "SwitchInput",
            label: "需要日程",
            keyWord: "usePoster",
            // readOnly:false
          },
        ],
      },
    ],
  };
};

const phoneNumberDic = [];

const setPhoneNumberDic = (id, phone, name) => {
  if (!phoneNumberDic.find((x) => x.id === id)) {
    phoneNumberDic.push({ id: id, phone: phone, name: name });
  }
};

/**
 * Main component
 * @param {*} props
 * @returns
 */
const OutsideMeetingDetail = (props) => {
  const { id } = useParams();
  const fromsServer = id === "fromServer";
  const pageLocation = useLocation();
  let meetingId = id;
  if (fromsServer) {
    const {
      state: { serverId },
    } = pageLocation;
    meetingId = serverId;
  }
  const navigate = useNavigate();
  if (!meetingId) {
    navigate("/404");
  }

  const [nowCustomerType, setNowCustomerType] = useState();
  const [customerShow, setCustomerShow] = useState(false);
  const [formVariable, setFormVariable] = useState({});

  const [schedules, setSchedules] = useState([]);
  const [refreshkey, setrefreshkey] = useState(new Date());
  const [selectorCustomerDic, setSelectorCustomerDic] = useState([]);
  const [dicSelectorShow, setDicSelectorShow] = useState(false);
  const [nowScheduleIndex, setNowScheduleIndex] = useState();
  const [meetingstartstr, setMeetingstartstr] = useState();
  const [meetingNo, setMeetingNo] = useState("");

  const fromLayOutInfo = defaultFromLayOut(
    setNowCustomerType,
    setCustomerShow,
    setFormVariable
  );
  const [fromLayOut, setFormLayOut] = useState(fromLayOutInfo);

  const storageKey = `${meetingId}_meeting${fromsServer ? "_server" : ""}`;
  const firstUpdate = useRef(true);

  //first time init
  useEffect(() => {
    let storageValue = getStorageObj(storageKey);
    if (!storageValue && fromsServer) {
      //get meeting by id
      getMeetingById(meetingId).then((result) => {
        setMeetingNo(result.data.result.meeting.meetingNo);
        setStorageObj(storageKey, result.data.result);
        setMeetingstartstr(result.data.result.meeting.startTime);
        convertToViewData(
          result.data.result,
          setFormVariable,
          setSchedules
        ).then(() => {
          firstUpdate.current = false;
        });
      });
    } else {
      setMeetingNo(storageValue.meeting.meetingNo);
      setMeetingstartstr(storageValue.meeting.startTime);
      convertToViewData(storageValue, setFormVariable, setSchedules).then(
        () => {
          firstUpdate.current = false;
        }
      );
    }
  }, []);

  //when formVariable change update customer dictionary
  useEffect(() => {
    const customerDic = updateCustomerDic(setSelectorCustomerDic, formVariable);
    if (!firstUpdate.current) {
      checkScheduleCustomerId(customerDic, schedules, setSchedules);
    }
  }, [formVariable]);

  //when formVariable or schedules change ,save in localstorage
  useLayoutEffect(() => {
    if (!firstUpdate.current) {
      var { postData } = convertToPostData(
        getStorageObj(storageKey),
        formVariable,
        schedules
      );
      setStorageObj(storageKey, postData);
    }
  }, [formVariable, schedules]);

  const buttons = [
    <Button
      style={{ width: "100%" }}
      fill="outline"
      shape="rounded"
      color="primary"
      onClick={navigate.bind(this, -1)}
    >
      上一步
    </Button>,
    <Button
      style={{ width: "100%" }}
      shape="rounded"
      color="primary"
      onClick={async () => {
        // 开始判断用户头像与绑定情况
        let res = await ApiGet("getSpeakerByMeetingId", {
          meetingId: meetingNo,
        });
        // console.log("res", res);
        const speakerData = res.data.result.result["speakers"];
        // data[0]['headImgUrl']=""
        // data[1]['headImgUrl']=""
        // console.log('data',data)
        //判断头像
        const hasNoHeadImg = speakerData.filter(
          (item) => item.headImgUrl == "" || item.headImgUrl == null
        );
        // console.log('hasNoHeadImg',hasNoHeadImg);
        // if(hasNoHeadImg && hasNoHeadImg.length>0){
        //     Dialog.confirm({
        //         content: '该客户暂无头像信息，需完善信息后方可参与会议',
        //         confirmText:'去完善',
        //         onConfirm: async () =>
        //         {
        //             navigate(`/customerDetail/${hasNoHeadImg[0]['id']}/${hasNoHeadImg[0]['speakerName']}/${hasNoHeadImg[0]['speakercrmCode']}`);
        //         },
        //     })
        //     return
        // }
        //判断绑定
        const hasNoBind = speakerData.filter(
          (item) => item.isBind === "false" || item.isBind === false
        );
        console.log("data", speakerData, hasNoHeadImg, hasNoBind);
        if (hasNoBind.length > 0 || hasNoHeadImg.length > 0) {
          Dialog.confirm({
            content:
              "有讲者信息尚未完善！请在医生360全景视图中绑定讲者为外部联系人并在会议系统中完善头像信息。",
            onConfirm: async () => {
              console.log("点击了确定");
            },
          });

          return false;
        }

        var { postData, effectEndTime } = convertToPostData(
          getStorageObj(storageKey),
          formVariable,
          schedules
        );
        if (
          effectEndTime.isAfter(
            moment(postData.meeting.endTime, "YYYY-MM-DD HH:mm")
          )
        ) {
          Toast.show({
            icon: "fail",
            content: "会议日程总时长已超过预定会议室时间。",
          });
          return;
        }

        const userInfo = GetUserInfo();
        postData.meeting.bu = userInfo.bu;
        //新增讲者数据
        const meetingPlaybills = [];
        const participants = [];
        speakerData.map((item) => {
          participants.push({
            name: item.speakerName,
            customerId: item.id,
            phone: item.mobile,
          });
        });

        meetingPlaybills.push({
          role: 3,
          participants,
        });
        postData.meetingPlaybills = meetingPlaybills;
        createOrEdit(postData).then((result) => {
          removeStorage(storageKey);
          if (fromsServer) {
            navigate(-1);
          } else {
            navigate("/meetingList");
          }
        });
      }}
    >
      保存
    </Button>,
  ];

  return (
    <div className="mainBody">
      {/* step */}
      <MeetingStep act={2}></MeetingStep>
      {/* customer */}
      {/* <FormGroups fromLayOut={fromLayOut} setFormLayOut={setFormLayOut} formVariable={formVariable}
                setFormVariable={setFormVariable}></FormGroups> */}

      {meetingNo && (
        <CustomerListDomSelect meetingId={meetingNo}></CustomerListDomSelect>
      )}

      {/* schedule from grups */}
      {formVariable.usePoster
        ? schedules.map((item, index) =>
            formDom(
              ScheduleDom(
                index,
                schedules,
                setSchedules,
                setDicSelectorShow,
                selectorCustomerDic,
                meetingstartstr,
                setNowScheduleIndex
              )
            )
          )
        : null}
      {/* add schedule btn */}
      {formVariable.usePoster
        ? formDom(
            <MultipleSelect
              label="添加日程"
              userDefinedPicker={() => {
                const tempSchedules = schedules.concat();
                tempSchedules.push(getDefaultSchedulesItem());
                setSchedules(tempSchedules);
              }}
              isLast={true}
            ></MultipleSelect>
          )
        : null}
      {/* click button */}
      <FormButtonBox
        buttons={buttons}
        style={{ marginTop: "3rem" }}
      ></FormButtonBox>
      {/* selector with customer dic */}
      <Picker
        columns={[selectorCustomerDic]}
        visible={dicSelectorShow}
        onClose={() => {
          setDicSelectorShow(false);
        }}
        onConfirm={(value) => {
          const customerInfo = selectorCustomerDic.find(
            (x) => x.value === value[0]
          );
          addScheduleCustomer(
            {
              key: customerInfo.value,
              value: customerInfo.label,
            },
            nowScheduleIndex,
            schedules,
            setSchedules,
            "customerId"
          );
          setDicSelectorShow(false);
        }}
      />
      {/* customer list */}
      <Popup
        visible={customerShow}
        onMaskClick={() => {
          setCustomerShow(false);
        }}
        bodyStyle={{
          height: "80vh",
          overflowY: "auto",
          overflowX: "hidden",
          backgroundColor: "#F5F7FA",
        }}
      >
        <NavBar
          back={null}
          left={
            <UndoOutline
              fontSize="1.2rem"
              onClick={() => {
                setrefreshkey(new Date());
              }}
            />
          }
          right={
            <CloseCircleOutline
              onClick={() => {
                setCustomerShow(false);
              }}
              fontSize="1.2rem"
            />
          }
        >
          选择客户
        </NavBar>

        {
          <CustomerListDom
            meetingId={meetingNo}
            state={1}
            titleText="客户"
            disableSerach={true}
            onItemClick={(customer) => {
              setPhoneNumberDic(customer.id, customer.phone, customer.name);
              setToFormData(
                formVariable,
                setFormVariable,
                nowCustomerType,
                customer
              );
              setCustomerShow(false);
            }}
            key={refreshkey}
          />
        }
      </Popup>
    </div>
  );
};

const convertToPostData = (postData, nowFormVariable, nowSchedules) => {
  let effectEndTime = moment(postData.meeting.startTime, "YYYY-MM-DD HH:mm");
  const formVariable = { ...nowFormVariable };
  const schedules = JSON.parse(JSON.stringify(nowSchedules));
  const meetingPlaybills = [];
  const meetingSchedules = [];
  const usePoster = formVariable.usePoster;
  for (var key in formVariable) {
    if (Array.isArray(formVariable[key])) {
      const participants = [];
      formVariable[key].forEach((item) => {
        const phoneNumber = phoneNumberDic.find(
          (x) => x.id === Number(item.key)
        );
        participants.push({
          name: item.value,
          customerId: item.key,
          phone: phoneNumber && phoneNumber.phone,
        });
      });
      meetingPlaybills.push({
        role: key,
        participants,
      });
    }
  }
  if (usePoster) {
    schedules.forEach((item) => {
      effectEndTime.add(item.meetingTime, "m");
      item.customerId = Array.isArray(item.customerId)
        ? item.customerId.map((x) => x.key).join()
        : item.customerId;
      meetingSchedules.push(item);
    });
  }
  postData.meetingPlaybills = meetingPlaybills;
  postData.meetingSchedules = meetingSchedules;
  return { postData, effectEndTime };
};

const convertToViewData = (postData, setFormVariable, setSchedules) => {
  return new Promise((resolve, reject) => {
    const formVariable = {};
    postData.meetingPlaybills &&
      Array.isArray(postData.meetingPlaybills) &&
      postData.meetingPlaybills.forEach((item) => {
        formVariable[item.role] = formVariable[item.role] || [];
        item.participants.forEach((playbill) => {
          formVariable[item.role].push({
            key: playbill.customerId,
            value: playbill.name,
          });
          setPhoneNumberDic(playbill.customerId, playbill.phone, playbill.name);
        });
      });
    let usePoster = false;
    postData.meetingSchedules &&
      Array.isArray(postData.meetingSchedules) &&
      postData.meetingSchedules.forEach((item) => {
        item.customerId = item.customerId.split(",").map((id) => {
          console.log("phoneNumberDic", phoneNumberDic);
          const customer = phoneNumberDic.find((x) => x.id === Number(id));
          return { key: id, value: customer ? customer.name : "" };
        });
        usePoster = true;
      });
    formVariable.usePoster = usePoster;
    setFormVariable(formVariable);
    usePoster && setSchedules(postData.meetingSchedules);
    console.log("meetingSchedules", postData.meetingSchedules);
    resolve();
  });
};

const createOrEdit = (postData) => {
  return ApiPost("createOrEditMeeting", postData);
};

const getMeetingById = (id) => {
  return ApiPost("getMeetingById", { id });
};

const updateCustomerDic = (setSelectorCustomerDic, formVariable) => {
  let customerDic = [];
  let dicIdList = [];
  for (let key in formVariable) {
    formVariable[key] &&
      Array.isArray(formVariable[key]) &&
      formVariable[key].forEach((x) => {
        if (!dicIdList.includes(x.key)) {
          customerDic.push({ label: x.value, value: x.key });
          dicIdList.push(x.key);
        }
      });
  }
  setSelectorCustomerDic(customerDic);
  return customerDic;
};

const setToFormData = (
  formVariable,
  setFormVariable,
  nowCustomerType,
  customer
) => {
  const tempData = { ...formVariable };
  if (!tempData[nowCustomerType]) {
    tempData[nowCustomerType] = [];
  }
  if (tempData[nowCustomerType].find((x) => x.key === customer.id)) {
    return;
  }
  tempData[nowCustomerType].push({ key: customer.id, value: customer.name });
  setFormVariable(tempData);
};

const getDefaultSchedulesItem = () => {
  return {
    // meetingId: 0,
    customerId: [], //{ key: 1, value: '666' }
    subject: "",
    meetingTime: 0,
    linkClass: 1,
    sort: 0,
    // id: 0
  };
};

const ScheduleDom = (
  index,
  schedules,
  setSchedules,
  setDicSelectorShow,
  selectorCustomerDic,
  meetingStart,
  setNowScheduleIndex
) => {
  const meetingStartTime = moment(meetingStart);
  const meetingEndTime = moment(meetingStart);

  for (let i = 0; i < index; i++) {
    meetingStartTime.add(schedules[i].meetingTime, "m");
    meetingEndTime.add(schedules[i].meetingTime, "m");
  }
  meetingEndTime.add(schedules[index].meetingTime, "m");
  const timeFormat = `(${meetingStartTime.format(
    "HH:mm"
  )}-${meetingEndTime.format("HH:mm")})`;

  return [
    <SelectInput
      valueDetail={schedules[index]}
      label="环节分类"
      keyWord="linkClass"
      options={[
        [
          { label: "致辞", value: 1 },
          { label: "讲课", value: 2 },
          { label: "讨论", value: 3 },
          { label: "点评", value: 4 },
          { label: "总结", value: 5 },
        ],
      ]}
      onValueChange={(value) =>
        setScheduleValue(value, index, schedules, setSchedules, "linkClass")
      }
    ></SelectInput>,
    <SelectInput
      valueDetail={schedules[index]}
      label="会议时长"
      keyWord="meetingTime"
      valueFormat={`{value}${timeFormat}`}
      options={[
        [
          { label: "1分钟", value: 1 },
          { label: "2分钟", value: 2 },
          { label: "3分钟", value: 3 },
          { label: "4分钟", value: 4 },
          { label: "5分钟", value: 5 },
          { label: "6分钟", value: 6 },
          { label: "7分钟", value: 7 },
          { label: "8分钟", value: 8 },
          { label: "9分钟", value: 9 },
          { label: "10分钟", value: 10 },
          { label: "15分钟", value: 15 },
          { label: "20分钟", value: 20 },
          { label: "25分钟", value: 25 },
          { label: "30分钟", value: 30 },
          { label: "35分钟", value: 35 },
          { label: "40分钟", value: 40 },
          { label: "45分钟", value: 45 },
          { label: "50分钟", value: 50 },
          { label: "55分钟", value: 55 },
          { label: "60分钟", value: 60 },
        ],
      ]}
      onValueChange={(value) =>
        setScheduleValue(value, index, schedules, setSchedules, "meetingTime")
      }
    ></SelectInput>,
    [2, 3, 4].indexOf(schedules[index].linkClass) >= 0 ? (
      <FormInput
        label="会议主题"
        valueDetail={schedules[index]}
        keyWord="subject"
        onValueChange={(value) =>
          setScheduleValue(value, index, schedules, setSchedules, "subject")
        }
      ></FormInput>
    ) : null,
    <MultipleSelect
      label={schedules[index].linkClass === 3 ? "讨论嘉宾" : "会议讲者"}
      valueDetail={schedules[index]}
      keyWord="customerId"
      userDefinedPicker={() => {
        if (selectorCustomerDic.length > 0) {
          setNowScheduleIndex(index);
          setDicSelectorShow(true);
        } else {
          Toast.show({ content: "请先选择参会人员" });
        }
      }}
      onItemRemove={(item, itemIndex) => {
        removeScheduleCustomer(
          index,
          schedules,
          setSchedules,
          "customerId",
          itemIndex
        );
      }}
    ></MultipleSelect>,
    <div style={{ display: "flex", padding: "1rem" }}>
      <div style={{ width: "100%", textAlign: "left" }}>
        <Button
          disabled={index === 0}
          onClick={sortScheduleItem.bind(
            this,
            index,
            schedules,
            setSchedules,
            index - 1
          )}
          fill="outline"
          style={{ "--border-color": "#FFF", fontWeight: "800" }}
        >
          <UpOutline />
          上移
        </Button>
      </div>
      <div style={{ width: "100%" }}>
        <Button
          fill="outline"
          onClick={removeScheduleItem.bind(
            this,
            index,
            schedules,
            setSchedules
          )}
          color="danger"
          style={{ "--border-color": "#FFF", fontWeight: "800" }}
        >
          删除
        </Button>
      </div>
      <div style={{ width: "100%", textAlign: "right" }}>
        <Button
          disabled={index === schedules.length - 1}
          onClick={sortScheduleItem.bind(
            this,
            index,
            schedules,
            setSchedules,
            index + 1
          )}
          fill="outline"
          style={{ "--border-color": "#FFF", fontWeight: "800" }}
        >
          下移
          <DownOutline />
        </Button>
      </div>
    </div>,
  ];
};

const addScheduleCustomer = (
  value,
  index,
  schedules,
  setSchedules,
  keyWord
) => {
  const tempSchedules = schedules.concat();
  if (!Array.isArray(tempSchedules[index][keyWord])) {
    tempSchedules[index][keyWord] = [];
  }
  //遍历schedules数据
  const dicIds = tempSchedules[index][keyWord]
    ? tempSchedules[index][keyWord].map((x) => x.value)
    : [];
  //判断value.value是否在dicIds中
  if (dicIds.indexOf(value.value) !== -1) {
    Toast.show({ content: "不可重复添加该成员" });
    return;
  } else {
    tempSchedules[index][keyWord].push(value);
    setSchedules(tempSchedules);
  }
};

const removeScheduleCustomer = (
  index,
  schedules,
  setSchedules,
  keyWord,
  itemIndex
) => {
  const tempSchedules = schedules.concat();
  tempSchedules[index][keyWord].splice(itemIndex, 1);
  setSchedules(tempSchedules);
};

const setScheduleValue = (value, index, schedules, setSchedules, keyWord) => {
  const tempSchedules = schedules.concat();
  tempSchedules[index][keyWord] = value;
  setSchedules(tempSchedules);
};

const removeScheduleItem = (index, schedules, setSchedules) => {
  const tempSchedules = schedules.concat();
  tempSchedules.splice(index, 1);
  setSchedules(tempSchedules);
};

const sortScheduleItem = (index, schedules, setSchedules, moveIndex) => {
  const tempSchedules = schedules.concat();
  const nowSchedules = tempSchedules[index];
  tempSchedules[index] = tempSchedules[moveIndex];
  tempSchedules[moveIndex] = nowSchedules;
  setSchedules(tempSchedules);
};

const checkScheduleCustomerId = (customerDic, schedules, setSchedules) => {
  const dicIds = customerDic ? customerDic.map((x) => x.value) : [];
  schedules &&
    schedules.forEach((schedule) => {
      if (schedule.customerId) {
        schedule.customerId = schedule.customerId.filter((customer) =>
          dicIds.includes(Number(customer.key))
        );
      }
    });
  setSchedules(schedules);
};

export default OutsideMeetingDetail;
