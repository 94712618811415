import React from "react"
import { isPc } from "../lib/apihelper";
import style from   './style/tabbar.module.css';
import getImage from '../lib/getImage';
import { matchPath, useNavigate } from 'react-router-dom';

 
const Tabbar = (props) =>
{
    const url = new URL(window.location.href);
    const navigate = useNavigate();

    return (
        <div className={isPc()?style.main_pc:style.main}>
            {menuList().map((item) =>
            {
                const iconDom = <img src={getImage(item.icon)}></img>;
                if (item.isCreate)
                {
                    return (
                        <div
                            onClick={() =>
                            {
                                navigate(item.path);
                                props.onPathChange && props.onPathChange(item.path);
                            }}
                            className={`${isPc()?style.createItem_pc:style.createItem} ${isPc()?style.baseItem_pc:style.baseItem}`}>
                            {iconDom}
                        </div>
                    )
                }
                else
                {
                    const iconActDom = <img src={getImage(item.actIcon)}></img>;
                    if (url.hash == `#${item.path}` || (item.matchHashPath && item.matchHashPath.includes(url.hash)))
                    {
                        item.isActive = true;
                    }
                    else if(url.hash=="#/" && item.path=="/meetingList"){
                        item.isActive = true;
                    }
                    // console.log('url.hash',url.hash)
                    // console.log('item.path',item.path,url.hash)
                    return (
                        <div
                            onClick={() =>
                            {

                                navigate(item.path);
                                props.onPathChange && props.onPathChange(item.path);
                            }}
                            className={`${isPc()?style.barItemNew_pc:style.barItemNew} ${isPc()?style.baseItem_pc:style.baseItem} ${item.isActive ? isPc()?style.barItemAct_pc:style.barItemAct: ''}`}>
                            {item.isActive ? iconActDom : iconDom}
                            <div className={isPc()?style.title_pc:style.title}>{item.title}</div>
                        </div>
                    )
                }
            })}
        </div>
    )
}
export default Tabbar;

function menuList ()
{
    return [
        // { title: "精彩会议", icon: 'dao_01.svg', actIcon: 'dao_01_press.svg', isCreate: false, path: '/' },
        { title: "我的会议", icon: 'dao_02.svg', actIcon: 'dao_02_press.svg', isCreate: false, path: '/meetingList', matchHashPath: ['#/MeetingListInside'] },
        { icon: 'dao_03.svg', actIcon: 'dao_03_press.svg', isCreate: true, path: '/createMeetingType' },
        { title: "我的客户", icon: 'dao_04.svg', actIcon: 'dao_04_press.svg', isCreate: false, path: '/CustomerList' },
        // { title: "会议数据", icon: 'dao_05.svg', actIcon: 'dao_05_press.svg', isCreate: false, path: '/meetingReport' },
    ];
}