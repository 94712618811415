const getImage = (src, isHeadImage) =>
{
    src = src === 'string' ? '' : src;
    const imgSrc = getNormalImage(src);
    return isHeadImage ? defaultHeadImage(imgSrc) : imgSrc;
}

const defaultHeadImage = (src) =>
{
    return src || "https://ECacheLive.ecache.com.cn//image/defaultImage.png";
}

const getNormalImage = (src) =>
{
    if (src && src.toLowerCase().indexOf("http") < 0)
    {
        return require(`../assets/${src}`);
    }
    return src;
}

export default getImage;