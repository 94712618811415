import React, { useState } from "react"
import { Checkbox, Input, Popup, DatePicker, Picker, Radio, Space, Switch } from 'antd-mobile'
import { DownOutline, AddCircleOutline, MinusCircleOutline, CheckCircleFill } from 'antd-mobile-icons'
import { isPc } from "../lib/apihelper";
import style from './style/forminput.module.css'
import getImage from '../lib/getImage';
import { useNavigate } from 'react-router-dom';
import moment from 'moment'

// const moment = require('moment');

/**
 * Base box
 * @param {*} props 
 * @param {*} inputdetail 
 * @param {*} helperDom 
 * @param {*} clickCallBack 
 * @returns 
 */
const mainBoxBase = (isLast, label, props, inputdetail, helperDom, clickCallBack) =>
{
    if (label)
    {
        return (
            <div className={style.main} onClick={() => clickCallBack && clickCallBack()}>
                <div className={[style.mianbox, isLast ? style.mainboxlast : ""].join(" ")} >
                    <label className={style.inputLabel} style={props.labelStyle} >{label}</label>
                    <div className={style.inputBox}>
                        {inputdetail}
                    </div>
                </div>
                {helperDom}
            </div>
        )
    }
    return null;
}

const propValue = (props) =>
{
    return props.valueDetail && props.valueDetail[props.keyWord];
}

const setPropValue = (props, value) =>
{
    const data = { ...props.valueDetail };
    if (props.setValueDetail)
    {
        data[props.keyWord] = value;
        props.setValueDetail(data);
    }

    props.onValueChange && props.onValueChange(value, data);
}

const valueFormat = (value, formatestr) =>
{
    if (!value)
    {
        return "";
    }
    if (formatestr)
    {
        return formatestr.replace("{value}", value);
    }
    return value;
}

const getplaceholder = (props, typeDisplay) =>
{
    if (props.placeholder)
    {
        return props.placeholder;
    }
    return typeDisplay + props.label;

}

const getSelectDisPlayName = (value, options) =>
{
    const item = options[0].find(x => x.value == value);
    if (item)
    {
        return item.label;
    }
    return null
}

/**
 * User Input
 * @param {*} props 
 * @returns 
 */
const FormInput = (props) =>
{
    const inputDom = <Input
        value={propValue(props)}
        style={{
            ...{
                '--text-align': 'right',
                '--placeholder-color': '#8B8C8E',
                '--font-size': '1rem'
            }, ...props.userDefinedStype
        }}
        readOnly={props.readOnly}
        onChange={(value) => setPropValue(props, value)}
        placeholder={getplaceholder(props, "请输入")}
        clearable />;
    return mainBoxBase(props.isLast, props.label, props, inputDom);
}

/**
 * User CheckBox
 * @param {*} props 
 * @returns 
 */
const CheckInput = (props) =>
{
    let checkDom;
    if (props.readOnly)
    {
        const iconColor = propValue(props) ? 'var(--adm-color-primary)' : '#DEE0E4';
        checkDom = <div style={{ width: '100%', textAlign: 'right' }}>
            <div className={style.dropDownIcon}>
                <CheckCircleFill fontSize='1.4rem' color={iconColor} />
            </div>
        </div>;
    }
    else
    {
        checkDom = <Checkbox checked={propValue(props)} onChange={(value) => setPropValue(props, value)} style={{ textAlign: 'right', '--color': '#000' }} />
    }
    return mainBoxBase(props.isLast, props.label, props, checkDom)
}

/**
 * User SwitchBox
 * @param {*} props 
 * @returns 
 */
const SwitchInput = (props) =>
{
    let myprops = { ...props };
    if (!props.labelStyle)
    {
        const propLabelStyle = { labelStyle: { color: "#000" } };
        myprops = { ...props, ...propLabelStyle };
    }
    let switchDom = <Switch checked={propValue(props)} onChange={(value) => setPropValue(props, value)} ></Switch>;
    return mainBoxBase(props.isLast, props.label, myprops, switchDom)
}


/**
 * User Select
 * @param {*} props 
 * @returns 
 */
const SelectInput = (props) =>
{
    const [showSelect, setShowSelect] = useState(false);
    const selectDom = <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => !props.readOnly && setShowSelect(true)} >
        <Input
            value={valueFormat(getSelectDisPlayName(propValue(props), props.options), props.valueFormat)}
            className={style.input}
            style={{
                '--text-align': 'right',
                '--placeholder-color': '#8B8C8E',
                '--font-size': '1rem'
            }}
            placeholder={getplaceholder(props, "请选择")}
            readOnly
        />
        <div className={style.dropDownIcon}>
            <DownOutline />
        </div>
    </div>;
    const helperDom = <Picker
        columns={props.options}
        visible={showSelect}
        onClose={() =>
        {
            setShowSelect(false)
        }}
        value={[propValue(props)]}
        onConfirm={(value) => setPropValue(props, value[0])}
    />;
    return mainBoxBase(props.isLast, props.label, props, selectDom, helperDom);
}

/**
 * DateTime Select
 * @param {*} props 
 * @returns 
 */
const DateInput = (props) =>
{
    const [showDate, setShowDate] = useState(false);
    let Value = propValue(props);
    const dateDom = <div style={{ display: 'flex', alignItems: 'center' }} onClick={() => !props.readOnly && setShowDate(true)}>
        <Input
            className={style.input}
            value={valueFormat(Value ? moment(Value).format("YYYY-MM-DD HH:mm") : Value, props.valueFormat)}
            style={{
                '--text-align': 'right',
                '--placeholder-color': '#8B8C8E',
                '--font-size': '1rem'
            }}
            placeholder={getplaceholder(props, "请选择")}
            readOnly />
        <div className={style.dropDownIcon}>
            <DownOutline />
        </div>
    </div>;
    const helperDom = <DatePicker
        visible={showDate}
        onClose={() =>
        {
            setShowDate(false)
        }}
        renderLabel={labelRenderer}
        precision='minute'
        // propLabelStyle={{ width: '300px'  }} 
        // style={{ width: '300px',display:'flex',justifyContent:'center',alignItems:'center' }} 
        // popupClassName={isPc()?style.adm_picker_pc:''}
        className="w-full"
        onConfirm={value =>
        {
            setPropValue(props, moment(value).format("YYYY-MM-DD HH:mm"))
            {/* Toast.show(val.toString()) */ }
        }}
        min={props.min || moment().add(-1, 'y').toDate()}
        max={props.max || moment().add(1, 'y').toDate()}
    />;
    return [mainBoxBase(props.isLast, props.label, props, dateDom, helperDom)];
}

const labelRenderer = (type, data) =>
{
    switch (type)
    {
        case 'year':
            return data + '年'
        case 'month':
            return data + '月'
        case 'day':
            return data + '日'
        case 'hour':
            return data + '时'
        case 'minute':
            return data + '分'
        case 'second':
            return data + '秒'
        default:
            return data
    }
}

/**
 * Radio Group item
 * @param {*} props 
 * @returns 
 */
const RadioInput = (props) =>
{
    let getRadioDom;
    if (props.readOnly)
    {
        getRadioDom = (index) =>
        {
            const iconColor = propValue(props) && propValue(props) === props.options[index].value ? 'var(--adm-color-primary)' : '#DEE0E4';
            return <div style={{ width: '100%', textAlign: 'right' }}>
                <div className={style.dropDownIcon}>
                    <CheckCircleFill fontSize='1.4rem' color={iconColor} />
                </div>
            </div>;
        }
    }
    else
    {
        getRadioDom = (index) => <Radio value={props.options[index].value} style={{ textAlign: 'right', '--color': '#000' }} />;
    }

    const radiosArr = [];
    for (let index = 0; index < props.options.length; index++)
    {
        let last = false;
        if (index === props.options.length - 1)
        {
            last = props.isLast;
        }
        const RadioDom = getRadioDom(index); //<Radio value={props.options[index].value} style={{ textAlign:'right','--color':'#000'}} />;
        radiosArr.push(mainBoxBase(last, props.options[index].label, props, RadioDom));
    }
    const result = <Radio.Group value={valueFormat(propValue(props), props.valueFormat)} onChange={(value) => setPropValue(props, value)}>
        {radiosArr}
    </Radio.Group>
    return result;
}

/**
 * Multiple Select
 * @param {*} props 
 * @returns 
 */
const MultipleSelect = (props) =>
{

    let myprops = { ...props };
    if (!props.labelStyle)
    {
        const propLabelStyle = { labelStyle: { color: "#000" } };
        myprops = { ...props, ...propLabelStyle };
    }
    let helperDom = null, onAddBtnClick;
    const [showSelect, setShowSelect] = useState(false);
    if (props.userDefinedPicker)
    {
        onAddBtnClick = props.userDefinedPicker;
    }
    else
    {
        helperDom = <Picker
            columns={props.options}
            visible={showSelect}
            onClose={() =>
            {
                setShowSelect(false)
            }}
            onConfirm={(value, extend) =>
            {
                const data = { ...props.valueDetail };
                if (!data[props.keyWord])
                {
                    data[props.keyWord] = [];
                }
                if (data[props.keyWord].findIndex(x => x.key === value[0]) < 0)
                {
                    data[props.keyWord].push({ key: value[0], value: extend.items[0].label });
                    props.setValueDetail(data);
                }

            }}
        />;
        onAddBtnClick = () => setShowSelect(true);
    }

    const selectDom = <div style={{ width: '100%', textAlign: 'right' }} onClick={() => !props.readOnly && onAddBtnClick(props.fromLayOutSettings)}>
        <div className={style.dropDownIcon}>
            {(props.readOnly ? null : <AddCircleOutline fontSize='1.4rem' />)}
        </div>
    </div>;
    const Values = propValue(props);
    const resultDoms = [mainBoxBase(Values && Values.length > 0 ? false : props.isLast, props.label, myprops, selectDom, helperDom)]
    Values && Array.isArray(Values) && Values.forEach((item, index) =>
    {
        const itemDom = <div style={{ width: '100%', textAlign: 'right' }} onClick={() =>
        {
            if (props.readOnly)
            {
                return;
            }
            if (props.onItemRemove)
            {
                props.onItemRemove(Values, index, props.valueDetail);
                return;
            }
            const data = { ...props.valueDetail };
            const dataIndex = data[props.keyWord].findIndex(x => x.key === item.key);
            data[props.keyWord].splice(dataIndex, 1);
            props.setValueDetail(data);
        }} >
            <div style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <span style={{ fontSize: '1rem' }}>{item.value}</span>
            </div>
            <div className={style.dropDownIcon} style={{ display: 'inline-block', verticalAlign: 'middle' }}>
                {(props.readOnly ? null : <MinusCircleOutline fontSize='1.4rem' />)}
            </div>
        </div>;
        const isLast = Values.length - 1 === index ? props.isLast : false;
        resultDoms.push(mainBoxBase(isLast, props.label, props, itemDom));
    });
    return resultDoms;
}

const UserDefinedInput = (props) =>
{
    return mainBoxBase(props.isLast, props.label, props, props.myInput);
}

export { FormInput, CheckInput, SelectInput, DateInput, RadioInput, MultipleSelect, SwitchInput, UserDefinedInput, mainBoxBase };