import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import style from './style/customerDetail.module.css';
import getImage from '../lib/getImage';
import { FormGroups } from '../component/formgroups';
import FormButtonBox from '../component/formbuttonbox';
import { Button, Image, Toast } from 'antd-mobile'
import { ApiGet, ApiPost } from '../lib/apihelper';


const getDefaultFromLayOut = (customer, setFormVariable, hasHeadImage) =>
{
    return {
        main: [
            {
                layOut: [
                    {
                        type: "FormInput",
                        label: "专家姓名",
                        keyWord: "name",
                        placeholder: "未获取专家姓名",
                        readOnly: true
                    },
                    {
                        type: "FormInput",
                        label: "医院名称",
                        keyWord: "hospitalNmae",
                        placeholder: "未获取医院名称",
                        readOnly: true
                    },
                    {
                        type: "FormInput",
                        label: "科室名称",
                        keyWord: "departmentNmae",
                        placeholder: "未获取科室名称",
                        readOnly: true
                    },
                    {
                        type: "FormInput",
                        label: "专家职称",
                        keyWord: "title",
                        placeholder: "未获取专家职称",
                        readOnly: true
                    }
                ]
            },
            {
                layOut: [
                    // {
                    //     type: "FormInput",
                    //     label: "联系方式",
                    //     keyWord: "phone",
                    //     placeholder: "未获取联系方式",
                    //     readOnly: true
                    // },
                    {
                        type: "UserDefinedInput",
                        label: "上传头像",
                        myInput: (
                            <div style={{ width: '100%', textAlign: 'right' }}>
                                <label for='headImageUpload'>
                                    <Image
                                        src={getImage(customer.headImgUrl)}
                                        style={{ borderRadius: '1.5rem', display: 'inline-block' }}
                                        fit='cover'
                                        width={'3rem'}
                                        height={'3rem'}
                                    />
                                </label>
                                {/*{hasHeadImage ? null : <input onChange={e => uploadHeadImage(e, customer, setFormVariable)} id='headImageUpload' className="fileinp" type="file" accept=".jpg,.jpeg,.png" style={{ display: 'none' }} />}*/}
                                <input onChange={e => uploadHeadImage(e, customer, setFormVariable)} id='headImageUpload' className="fileinp" type="file" accept=".jpg,.jpeg,.png" style={{ display: 'none' }} />
                            </div>
                        )

                    }
                ]
            }
        ]
    }
}



const CustomerDetail = (props) =>
{
    const { id, name, code } = useParams();
    const [formVariable, setFormVariable] = useState({});
    const [fromLayOut, setFormLayOut] = useState({ main: [] });
    const [hasHeadImage, setHasHeadImage] = useState();
    useEffect(() =>
    {
        if (id != 0)
        {
            ApiPost('getCustomersById', { id }).then((result) =>
            {
                setFormVariable(result.data.result);
                setHasHeadImage(result.data.result.headImgUrl);
            });
        }
        else
        {
            ApiPost("queryDoctorInfo", { doctorName: name }).then((result) =>
            {
                const doctors = result.data.result.result.doctorInfo;
                let doctor = doctors.find(x => x.companyDoctorId == code);
                doctor = doctor || doctors[0];
                doctor = {
                    "name": doctor["doctorName"],
                    "hospitalCode": doctor["hospitalId"],
                    "hospitalNmae": doctor["companyHospital"],
                    "departmentCode": doctor["companyDepartment"],
                    "departmentNmae": doctor["companyDepartment"],
                    "title": doctor["doctorTitle"],
                    "phone": doctor["phone"],
                    "headImgUrl": doctor["headImgUrl"],
                    "doctorId": doctor["companyDoctorId"],
                    "innerOuter": doctor["innerOuter"],
                    "id": 0
                };
                setFormVariable(doctor);
                setHasHeadImage(doctor.headImgUrl);
            });
        }
    }, []);

    useEffect(() =>
    {
        setFormLayOut(getDefaultFromLayOut(formVariable, setFormVariable, hasHeadImage));
    }, [formVariable]);

    const navigate = useNavigate();
    const buttons = [<Button style={{ width: '50%', fontWeight: 'bold' }} shape='rounded' color='primary' onClick={() =>
    {
        if (!formVariable.headImgUrl)
        {
            Toast.show('请先上传头像');
            return;
        }
        ApiPost('updateCustomer', formVariable).then(() => { navigate(-1) });

    }} >确认</Button>];

    return (
        <div className="mainBody">
            <FormGroups fromLayOut={fromLayOut} setFormLayOut={setFormLayOut} formVariable={formVariable} setFormVariable={setFormVariable} ></FormGroups>
            {
                // formVariable.phone=='' ? fromLayOut.main[1].layOut[0].readOnly=false:fromLayOut.main[1].layOut[0].readOnly=true

            }
            <FormButtonBox buttons={buttons} style={{ marginTop: '3rem' }} ></FormButtonBox>
        </div>
    )
}


export default CustomerDetail;

const uploadHeadImage = (e, customer, setFormVariable) =>
{
    // setTimeout(()=>{
    //     let newData = { ...customer };
    //     newData.headImgUrl = "https://websites.ecache.com.cn/techpoolImage/20220829/41d45fc3c9cd44ebb0bc6dce98b2fbcd.jpg?x-oss-process=image/auto-orient,1/interlace,1/resize,p_15/quality,Q_85";
    //     setFormVariable(newData);
    // },500);
    //
    // return;
    let file = e.target.files[0]
    if (!file)
        return;
    let param = new FormData()       // 创建form对象     
    param.append('file', file)       // 通过append向form对象添加数据
    let config = {
        headers: { 'Content-Type': 'multipart/form-data' }
    }
    ApiPost('uploadFile', param, true, config).then(result =>
    {
        Toast.show({
            icon: 'success',
            content: '上传成功',
            duration: 1000,
            maskClickable: false,
        })
        let newData = { ...customer };
        newData.headImgUrl = result.data.result;
        setFormVariable(newData);
    });
}