import axios from "axios";
import { Toast } from "antd-mobile";
import { setStorage, getStorage } from "./localtools";

/**
 * base Url
 */
const baseUrl = 'https://techpool.ecache.com.cn/';
// const baseUrl = "https://techpool-test.ecache.com.cn/";
// const baseUrl = 'http://localhost:21021/';

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  headers: {
    "X-Custom-Header": "foobar",
    Authorization: `Bearer ${getStorage("Login.Token")}`,
  },
});

const axiosWithOutToken = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
  headers: {
    "X-Custom-Header": "foobar",
  },
});

const HttpGet = (url, params, disableLoading, config, disableErrorAlert) => {
  disableLoading ||
    Toast.show({
      icon: "loading",
      content: "加载中…",
      duration: 0,
      maskClickable: false,
    });
  return new Promise((resolve, reject) => {
    instance
      .get(url, { params }, config)
      .then((result) => {
        disableLoading || Toast.clear();
        resolve(result);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          automaticLogIn().then(() => {
            HttpGet(url, params, false);
          });
        } else {
          disableLoading || Toast.clear();
          !disableErrorAlert &&
            Toast.show({
              icon: "fail",
              content: error.response.data.error.message,
            });
          reject(error);
        }
      });
  });
};

const ApiGet = (apiType, params, disableLoading, config, disableErrorAlert) => {
  return HttpGet(
    apiDetail[apiType],
    params,
    disableLoading,
    config,
    disableErrorAlert
  );
};

const ApiGetWithOutAuth = (
  apiType,
  params,
  disableLoading,
  config,
  disableErrorAlert
) => {
  disableLoading ||
    Toast.show({
      icon: "loading",
      content: "加载中…",
      duration: 0,
      maskClickable: false,
    });
  return new Promise((resolve, reject) => {
    axiosWithOutToken
      .get(apiDetail[apiType], { params }, config)
      .then((result) => {
        disableLoading || Toast.clear();
        resolve(result);
      })
      .catch((error) => {
        disableLoading || Toast.clear();
        !disableErrorAlert &&
          Toast.show({
            icon: "fail",
            content: error.response.data.error.message,
          });
        reject(error);
      });
  });
};

const HttpPost = (url, params, disableLoading, config, disableErrorAlert) => {
  disableLoading ||
    Toast.show({
      icon: "loading",
      content: "加载中…",
      duration: 0,
      maskClickable: false,
    });
  return new Promise((resolve, reject) => {
    instance
      .post(url, params, config)
      .then((result) => {
        disableLoading || Toast.clear();
        if (canShowSuccess(url)) {
          Toast.show({
            icon: "success",
            content: "保存成功",
            duration: 1000,
            maskClickable: false,
            afterClose: () => {
              resolve(result);
            },
          });
        } else {
          resolve(result);
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          automaticLogIn().then(() => {
            HttpPost(url, params, false)
              .then((newResult) => {
                resolve(newResult);
              })
              .catch((newError) => {
                reject(newError);
              });
          });
        } else {
          disableLoading || Toast.clear();
          !disableErrorAlert &&
            Toast.show({
              icon: "fail",
              content: error.response.data.error.message,
            });
          reject(error);
        }
      });
  });
};

const ApiPost = (
  apiType,
  params,
  disableLoading,
  config,
  disableErrorAlert
) => {
  return HttpPost(
    apiDetail[apiType],
    params,
    disableLoading,
    config,
    disableErrorAlert
  );
};

const ApiPostWithOutAuth = (
  apiType,
  params,
  disableLoading,
  config,
  disableErrorAlert
) => {
  disableLoading ||
    Toast.show({
      icon: "loading",
      content: "加载中…",
      duration: 0,
      maskClickable: false,
    });
  return new Promise((resolve, reject) => {
    axiosWithOutToken
      .post(apiDetail[apiType], params, config)
      .then((result) => {
        disableLoading || Toast.clear();
        resolve(result);
      })
      .catch((error) => {
        disableLoading || Toast.clear();
        !disableErrorAlert &&
          Toast.show({
            icon: "fail",
            content: error.response.data.error.message,
          });
        reject(error);
      });
  });
};

const ClearToken = () => {
  instance.defaults.headers["Authorization"] = "";
};

/**
 * 检测是否为PC端
 * @returns
 */
const isPc = () => {
  var ua = navigator.userAgent,
    isWindowsPhone = /(?:Windows Phone)/.test(ua),
    isSymbian = /(?:SymbianOS)/.test(ua) || isWindowsPhone,
    isAndroid = /(?:Android)/.test(ua),
    isFireFox = /(?:Firefox)/.test(ua),
    // isChrome = /(?:Chrome|CriOS)/.test(ua),
    isTablet =
      /(?:iPad|PlayBook)/.test(ua) ||
      (isAndroid && !/(?:Mobile)/.test(ua)) ||
      (isFireFox && /(?:Tablet)/.test(ua)),
    isPhone = /(?:iPhone)/.test(ua) && !isTablet,
    isPc = !isPhone && !isAndroid && !isSymbian;
  return isPc;
};
const getWidth = () => {
  return { width: window.innerWidth };
};

export {
  baseUrl,
  HttpGet,
  ApiGet,
  ApiGetWithOutAuth,
  HttpPost,
  ApiPost,
  ApiPostWithOutAuth,
  ClearToken,
  isPc,
  getWidth,
};

const apiDetail = {
  bannerList: "api/services/app/BannerService/QueryByAllAsync",
  wonderfulMeeting:
    "api/services/app/MeetingsService/QueryWonderfulMeetingAsync",
  customerList: "api/services/app/CustomerService/QueryByAllAsync",
  myMeetingList: "api/services/app/MeetingsService/QueryMyMeetingAsync",
  createOrEditMeeting: "api/services/app/MeetingsService/CreateOrUpdateAsync",
  deleteMeeting: "/api/services/app/MeetingsService/DeleteAsync",
  getMeetingById: "api/services/app/MeetingsService/QueryMeeingByIdAsync",
  getCustomersByIds: "api/services/app/CustomerService/QueryByIdsAsync",
  getCustomersById: "api/services/app/CustomerService/QueryByIdAsync",
  updateCustomer: "api/services/app/CustomerService/CreateOrUpdateAsync",
  uploadFile: "api/services/app/CustomerService/UploadFileAsync",
  getPerformScience:
    "api/services/app/MeetingsService/QueryMeetingPerformScienceAndScreenshotByIdAsync",
  updateExternalMeeting:
    "/api/services/app/MeetingsService/UpdateExternalMeetingAsync",
  meetingParticipants:
    "/api/services/app/MeetingsService/QueryMeetingParticipants",
  getMeetingByCRM: "/api/services/app/Apis/GetMeetingByCRMAsync",
  queryDoctorInfo: "/api/services/app/Apis/QueryDoctorInfoAsync",
  getSpeakerByMeetingId: "/api/services/app/Apis/GetSpeakerByMeetingIdAsync",
  getIsRegisterByunionId: "/api/services/app/Apis/GetIsRegisterByunionIdAsync",
  getPhoneCode: "/api/services/app/MeetingsService/GetPhoneCode",
  getMeetingInfoByQRCode:
    "/api/services/app/MeetingsService/GetMeetingInfoByQRCodeAsync",
  logwatch: "/api/services/app/MeetingsService/WatchAsync",
  meetingSign: "/api/services/app/MeetingsService/SignAsync",
  registerMeetingUser: "/api/services/app/Apis/RegisterAsync",
  getMeeingReport: "/api/services/app/MeetingsService/GetMeeingReportAsync",
  updateDoctor: "/api/services/app/Apis/UpdateDoctorAsync",
};

const showSuccessList = [
  apiDetail.createOrEditMeeting,
  apiDetail.deleteMeeting,
  apiDetail.updateCustomer,
];

const canShowSuccess = (url) => {
  return showSuccessList.includes(url);
};

var isLogining = false;

var logInResolves = [];

const automaticLogIn = async () => {
  return new Promise((resolve, reject) => {
    if (!isLogining) {
      isLogining = true;
      logInMain().then(() => {
        resolve();
        logInResolves.forEach((item) => {
          item && item();
        });
        logInResolves = [];
        isLogining = false;
      });
    } else {
      logInResolves.push(resolve);
    }
  });
};

const logInMain = () => {
  const loginId = getStorage("LoginId");
  if (!loginId) {
    // window.location.href = "https://www.baidu.com";
    return;
  }
  return instance
    .post("api/TokenAuth/AuthenticateByTechpool", {
      userNameOrEmailAddress: loginId,
      password: "111111",
    })
    .then((result) => {
      const token = result.data.result.accessToken;
      setStorage("Login.Token", token);
      setStorage("Login.Name", result.data.result.name);
      setStorage("Login.Role", result.data.result.role);
      setStorage("Login.EmployeeNo", result.data.result.employeeNo);
      setStorage("Login.Bu", result.data.result.bu);
      instance.defaults.headers["Authorization"] = `Bearer ${token}`;
    });
};
