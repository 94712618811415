import React from "react"
import style from './style/index.module.css'
import getImage from '../lib/getImage';

import { Swiper, Toast, ImageViewer } from 'antd-mobile'

//my component
import MeetingList from '../component/meetingList';
import ListBar from '../component/listbar';

//lib
import { ApiGet, ApiPost, baseUrl } from '../lib/apihelper';


class Index extends React.Component
{

    /**
    * tab for list default settings
    * @returns 
    */
    tabList = () =>
    {
        return [
            { name: '未开始', tabId: 1 },
            { name: '进行中', tabId: 2 },
            { name: '已结束', tabId: 3 },
        ];
    }


    constructor (props)
    {
        super(props);
        this.state = {
            nowTab: 1,
            bannerList: [],
            meetingList: [],
            searchText: '',
        };

        this.apiDataInit();

    }

    /**
     * Init data call All data API
     */
    apiDataInit = () =>
    {
        Toast.show({
            icon: 'loading',
            content: '加载中…',
            duration: 0,
            maskClickable: false,
        });
        Promise.all([this.getBanner(), this.getMeetingList(this.state.nowTab)])
            .then(() =>
            {
                Toast.clear();
            });
    }


    /**
     * get banner list api
     * @returns 
     */
    getBanner = () =>
    {
        const self = this;
        return ApiPost('bannerList', {}, true)
            .then((result) =>
            {
                self.setState({
                    bannerList: result.data.result.map(x => x.imgUrl)
                });
            }).catch(err =>
            {
            })
            ;
    }

    /**
     * get banner list api
     * @returns 
     */
    getMeetingList = (nowTab, filterOut) =>
    {
        const self = this;
        const params = {
            state: nowTab,
            filter: filterOut ? "" : this.state.searchText,
            maxResultCount: 1000,
            skipCount: 0
        };
        return ApiPost('wonderfulMeeting', params, true)
            .then((result) =>
            {
                self.setState({
                    meetingList: result.data.result.items
                });
            });
    }

    /**
     * when tab change 
     * @param {*} tabId 
     */
    onTabChange = (tabId) =>
    {
        this.setState({ nowTab: tabId });
        Toast.show({
            icon: 'loading',
            content: '加载中…',
            duration: 0,
            maskClickable: false,
        });
        this.getMeetingList(tabId).then(() =>
        {
            Toast.clear();
        });
    }



    /**
     * Banner dom
     * @returns 
     */
    bannerBox = () =>
    {
        return (
            <Swiper slideSize={100} trackOffset={0} loop stuckAtBoundary={false} block style={{ '--track-padding': ' 0 0 1rem' }} indicatorProps={{ style: { '--active-dot-color': '#FF6D3D', '--dot-color': '#757A82' } }}>
                {this.state.bannerList.map(item =>
                {
                    return (
                        <Swiper.Item style={{ padding: '0 .5rem', width: 'auto' }}>
                            <img className={style.bannerImg} src={getImage(item)} />
                        </Swiper.Item>
                    )
                })}
            </Swiper>
        )
    }

    render ()
    {
        return (
            <div className="mainBody">
                <div className={style.bannerBox}>{this.bannerBox()}</div>
                <ListBar
                    nowTab={this.state.nowTab}
                    tabList={this.tabList()}
                    onTabChange={(tabId) => { this.onTabChange(tabId) }}
                    onSearch={() =>
                    {
                        this.getMeetingList(this.state.nowTab);
                    }}
                    onSearchTextChange={(value) =>
                    {
                        this.setState({ searchText: value });
                    }}
                    onCancel={() =>
                    {
                        this.setState({ searchText: '' });
                        this.getMeetingList(this.state.nowTab, true);
                    }}
                ></ListBar>
                <MeetingList itemClick={(item) =>
                {
                    ImageViewer.show({
                        image: `${baseUrl}/ScheduleImages/viewer_${item.id}.jpg`
                    });
                }} meetingData={this.state.meetingList} ></MeetingList>
            </div>
        )
    }
}

export default Index;