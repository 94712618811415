import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import style from './style/OutsideMeetingCreate.module.css';
import getImage from '../lib/getImage';
import { FormGroups } from '../component/formgroups';
import FormButtonBox from '../component/formbuttonbox';
import { Button, Toast } from 'antd-mobile'
import MeetingStep from '../component/meetingStep';
import { setStorageObj, getStorageObj } from '../lib/localtools';
import moment from "moment";
import { meetingDateCalculate, convterToString } from '../lib/dateCalculate';

const getDefaultFromLayOut = (maxDate, minDate) =>
{
    return {
        main: [
            {
                title: "",
                layOut: [
                    {
                        type: 'FormInput',
                        label: "会议编号",
                        keyWord: "meetingNo",
                        readOnly: true
                    },
                ]
            },
            {
                style: { marginTop: '2rem' },
                layOut: [
                    {
                        type: 'FormInput',
                        label: "会议主题",
                        keyWord: "subject",
                        readOnly: true
                    },
                    {
                        type: 'FormInput',
                        label: "会议日期",
                        keyWord: "meetDate",
                        // placeholder:""
                        readOnly: true
                    },
                    {
                        type: 'DateInput',
                        label: "开始时间",
                        keyWord: "startTime",
                        min: minDate,
                        max: maxDate
                        // placeholder:""
                    },
                    {
                        type: 'DateInput',
                        label: "结束时间",
                        keyWord: "endTime",
                        min: minDate,
                        max: maxDate
                        // placeholder:""
                    },
                    {
                        type: "SelectInput",
                        label: "预计线上人数",
                        keyWord: "meetingNumber",
                        // placeholder:"",
                        valueFormat: "{value}人",
                        options: [
                            [
                                { label: "50", value: 50 },
                                { label: "300", value: 300 },
                                { label: "500", value: 500 },
                            ]
                        ],
                        // readOnly:false
                    },
                    // {
                    //     type: "CheckInput",
                    //     label: "是否公开会议",
                    //     keyWord: "isOpenMeeting",
                    //     // readOnly:false
                    // },
                    // {
                    //     type: "CheckInput",
                    //     label: "是否有线下客户参会",
                    //     keyWord: "isDepartmentMeeting",
                    //     // readOnly:false
                    // }
                ]
            }
        ]
    }
}

const defaultValueModel = {
    meetingType: 2,
    isOpenPwd: false,
    pwd: "",
    isOpenSignUp: true,
    isOpenWaitRoom: true,
    allowInBeforeHost: false,
    isOpenWatermark: true,
    isDepartmentMeeting: false,
    isOpenMute: 1,
    isActive: true
}
const OutsideMeetingCreate = (props) =>
{
    const { id, name, start, end } = useParams();
    const [formVariable, setFormVariable] = useState();
    const [fromLayOut, setFormLayOut] = useState();
    useEffect(() =>
    {
        const { minDate, maxDate } = meetingDateCalculate(start, end);
        const storageValue = getStorageObj(`${id}_meeting`);
        setFormVariable({ ...defaultValueModel, ...(storageValue && storageValue.meeting), ...{ meetingNo: id, subject: name, meetDate: convterToString(start, end) } });
        setFormLayOut(getDefaultFromLayOut(maxDate, minDate));
    }, []);

    const navigate = useNavigate();
    const buttons = [<Button style={{ width: '100%', fontWeight: 'bold' }} fill='outline' shape='rounded' color='primary' onClick={navigate.bind(this, -1)} >上一步</Button>, <Button style={{ width: '100%', fontWeight: 'bold' }} shape='rounded' color='primary' onClick={() =>
    {
        if (!formVariable.startTime || !formVariable.endTime)
        {
            Toast.show("开始与结束时间不能为空");
            return;
        }
        if (moment(formVariable.startTime).isSameOrAfter(formVariable.endTime))
        {
            Toast.show("结束时间需大于开始时间");
            return;
        }
        const storageValue = getStorageObj(`${id}_meeting`) || {};
        storageValue.meeting = formVariable;
        setStorageObj(`${id}_meeting`, storageValue);
        navigate(`/OutsideMeetingDetail/${id}`);
    }
    }>下一步</Button>];

    return (
        <div className="mainBody">
            <MeetingStep act={1} ></MeetingStep>
            <FormGroups fromLayOut={fromLayOut} setFormLayOut={setFormLayOut} formVariable={formVariable} setFormVariable={setFormVariable} ></FormGroups>
            <FormButtonBox buttons={buttons} style={{ marginTop: '3rem' }} ></FormButtonBox>
        </div>
    )
}

const getLayOutGroup = (layOut, groupIndex) =>
{
    return layOut.main[groupIndex];
}

const getLayOutItem = (layOut, groupIndex, itemIndex) =>
{
    return getLayOutGroup(layOut, groupIndex).layOut[itemIndex];
}

export default OutsideMeetingCreate;